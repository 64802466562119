<template>
    <loading
        v-model:active="mostrarLoader"
        color="#0F80C1"
        loader="dots"
    />

   <div class="row">
      <div class="col-xxl-12">
         <div class="course__slider swiper-container pb-60">

         <swiper
            ref="mySwiper"
            class="pb-50"
            :space-between="25"
            :slides-per-view="2"
            :modules="modules"
            :pagination="{clickable: true}"
            :loop="false"
            :breakpoints="breakpoints"
          >
             <swiper-slide v-for="course in courseItems.slice(0,9)" :key="course.token">
              <div class="course__item course__item-3 swiper-slide white-bg mb-30 fix">
                  <div class="course__thumb w-img p-relative fix">
                       <a :href="course.proximamente ? 'javascript:void(0);' : `${rutaCompleta}curso/${course.codigo}`">
                         <img v-if="course.course_image" :src="course.course_image" alt="course-image" style="">
                         <img v-else src="@/assets/img/pages/not-available.png" alt="course-image" style="">
                      </a>
                      <div class="course__tag">
                        <a v-if="course.proximamente" href="#" class="orange">Próximamente</a>
                        <a v-else-if="course.tipo_registro=='CURSO'" href="javascript:void(0);" class="green">Curso</a>
                        <a v-else-if="course.tipo_registro=='PROGRAMA'" href="javascript:void(0);" class="green">Programa</a>
                      </div>
                  </div>
                   <div class="course__content">
                      <div class="course__meta d-flex align-items-center justify-content-between" v-if="!course.proximamente">
                         <div class="course__lesson">
                            <span><i class="far fa-book-alt"></i>{{ course.modulos.length }} Secciones</span>
                         </div>
          <!--               <div class="course__rating">-->
          <!--                  <span><i class="icon_star"></i>{{course.rating}} (44)</span>-->
          <!--               </div>-->
                      </div>
                      <h3 class="course__title">
                         <a :href="course.proximamente ? 'javascript:void(0);' : `${rutaCompleta}curso/${course.codigo}`">
                            {{ course.name }}
                         </a>
                      </h3>
                      <div class="course__teacher d-flex align-items-center" v-for="teacher in course.teachers" :key="teacher.token">
                         <div class="course__teacher-thumb mr-15">
                            <a v-if="teacher.enlace_linkedin" :href="teacher.enlace_linkedin" target="_blank">
                              <img :src="teacher.avatar_image_url" alt="">
                            </a>

                            <img :src="teacher.avatar_image_url" alt="" v-else>
                         </div>
                         <h6>
                            <b>Docente: </b>
                            <a v-if="teacher.enlace_linkedin" :href="teacher.enlace_linkedin" target="_blank"> {{ teacher.display_name }}</a>
                            <a v-else href="#"> {{ teacher.display_name }}</a>
                         </h6>
                      </div>
                   </div>
                   <div class="course__more d-flex justify-content-between align-items-center" v-if="!course.proximamente">
                    <div class="course__status d-flex align-items-center" v-if="course.info_descuento_promocion && course.info_descuento_promocion.aplica_descuento">
                       <span class="green">$ {{ course.info_descuento_promocion.precio_postdescuento }}</span>
                       <span class="old-price">$ {{ course.precio}}</span>
                    </div>
                    <div class="course__status d-flex align-items-center" v-else-if="course.info_descuento_promocion && !course.info_descuento_promocion.aplica_descuento">
                       <span class="green">$ {{ course.precio }}</span>
                    </div>
                      <div class="course__btn">
                          <a :href="course.proximamente ? 'javascript:void(0);' : `${rutaCompleta}curso/${course.codigo}`" class="link-btn">
<!--                          <router-link :to="`/course-details/${course.token}`" class="link-btn">-->
                              Ver detalles
                              <i class="far fa-arrow-right"></i>
                              <i class="far fa-arrow-right"></i>
<!--                         </router-link>-->
                          </a>
                      </div>
                   </div>
               </div>
            </swiper-slide>
          </swiper>
            <!-- Add Pagination -->
            <div class="swiper-pagination"></div>
         </div>
      </div>
   </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from "swiper";
import CoursesMixin from '../../mixins/courseItemsMixin';

import axios from '@/axios';
import rutasjson from '@/assets/utils/rutas.json'

export default {
   name:'SliderCourses',
   mixins:[CoursesMixin],
    components: {
      Swiper,
      SwiperSlide,
   },
   data () {
      return {
          // breakpoints
         breakpoints: {
            550: { slidesPerView: 1,},
            768: { slidesPerView: 3,},
            992: { slidesPerView: 3,},
         },
        courseItems: [],
        mostrarLoader: false,
        rutaCompleta: rutasjson.rutaCompleta
      }
   },
   setup() {
    return {
      modules: [Pagination],
    };
  },
  methods:{
        getRandomCourses() {
            this.openLoadingInDiv()
            axios.get('api/curso/get_cursos_random/?limite=9').then(response => {
                this.courseItems = response.data.data;
                this.closeLoadingInDiv()
               }
            );
        },
        openLoadingInDiv(){
            this.mostrarLoader = true
        },
        closeLoadingInDiv(){
            this.mostrarLoader = false
        }
  },
  mounted() {
     this.getRandomCourses()
  }
};
</script>
