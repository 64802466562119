import { createWebHistory,createRouter } from "vue-router";
import Home from '../pages/Home.vue';
// import HomeTwo from '../pages/HomeTwo.vue';
// import HomeThree from '../pages/HomeThree.vue';
import Courses from '../pages/Courses.vue';
import Programas from '../pages/Programas.vue';
// import CoursesList from '../pages/CoursesList.vue';
// import CourseSidebar from '../pages/CourseSidebar.vue';
// import CourseDetails from '../pages/CourseDetails.vue';
// import Blog from '../pages/Blog.vue';
// import BlogDetails from '../pages/BlogDetails.vue';
import About from '../pages/About.vue';
// import Instructor from '../pages/Instructor.vue';
// import InstructorDetails from '../pages/InstructorDetails.vue';
// import EventDetails from '../pages/EventDetails.vue';
import Cart from '../pages/Cart.vue';
import Checkout from '../pages/Checkout.vue';
// import SignIn from '../pages/SignIn.vue';
// import SignUp from '../pages/SignUp.vue';
import ErrorPage from '../pages/ErrorPage.vue';
import Contact from '../pages/Contact.vue';
import CourseDetailsPage from '../pages/DynamicCourseDetails.vue';
// import DynamicBlogDetails from '../pages/DynamicBlogDetails.vue';
import TerminosCondiciones from '../pages/TerminosCondiciones.vue';
import PoliticasPrivacidad from '../pages/PoliticasPrivacidad.vue';
import EvidenciaPagoPage from '../pages/EvidenciaPago.vue';
// import CourseLandingPage from "@/pages/CourseLandingPage.vue";

import Blogs from '../pages/Blogs.vue';
import BlogDetalle from '../pages/BlogDetalle.vue';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    mode: 'history',
    base: process.env.BASE_URL,
    routes:[
        {
            path:'/',
            component:Home,
        },
        // {
        //     path:'/home',
        //     component:Home,
        // },
        // {
        //     path:'/home-two',
        //     component:HomeTwo,
        // },
        // {
        //     path:'/home-three',
        //     component:HomeThree,
        // },
        {
            path:'/cursos',
            component:Courses,
            name:'consulta-cursos'
        },
        {
            path:'/programas',
            component:Programas,
            name:'consulta-programas'
        },
        // {
        //     path:'/lista-cursos',
        //     component:CoursesList,
        // },
        // {
        //     path:'/courses-sidebar',
        //     component:CourseSidebar,
        // },
        // {
        //     path:'/course-details',
        //     component:CourseDetails,
        // },
        // {
        //     path:'/blog',
        //     component:Blog,
        // },
        // {
        //     path:'/blog-details',
        //     component:BlogDetails,
        // },
        {
            path:'/nosotros',
            component:About,
        },
        // {
        //     path:'/instructor',
        //     component:Instructor,
        // },
        // {
        //     path:'/instructor-details',
        //     component:InstructorDetails,
        // },
        // {
        //     path:'/event-details',
        //     component:EventDetails,
        // },
        {
            path:'/carrito',
            component:Cart,
        },
        {
            path:'/checkout',
            component:Checkout,
        },
        // {
        //     path:'/login',
        //     component:SignIn,
        // },
        // {
        //     path:'/register',
        //     component:SignUp,
        // },
        {
            path:'/errorPage',
            component:ErrorPage,
        },
        {
            path:'/contacto',
            component:Contact,
        },
        // {
        //     path:'/curso/:codigo/informacion',
        //     component:CourseLandingPage,
        // },
        // {
        //     path:'/course-details/:id',
        //     component:CourseDetailsPage,
        // },
        {
            path:'/curso/:codigo',
            component:CourseDetailsPage,
            name:'detalle-curso'
        },
        {
            path:'/programa/:codigo',
            component:CourseDetailsPage,
            name:'detalle-programa'
        },
        {
            path:'/terminos-y-condiciones',
            component:TerminosCondiciones,
        },
        {
            path:'/politicas-privacidad',
            component:PoliticasPrivacidad,
        },
        {
            path:'/checkout/:token',
            component:EvidenciaPagoPage,
        },
        {
            path:'/blogs',
            component:Blogs,
        },
        {
            path:'/blog/:token',
            component:BlogDetalle,
        },
        {
            path:'/landing/:token',
            component:Home,
        },
    ]
})

router.beforeEach((to, from, next) => {
    next();
    window.scrollTo(0, 0)
});

export default router
