<template>
  <loading
    v-model:active="mostrarLoader"
    color="#0F80C1"
    loader="dots"
  />
  <Header header__white="header__white" />
  <Breadcrumb title="Términos y Condiciones" subtitle="Términos y Condiciones" />

  <section class="pt-90 pb-50">
   <div class="container">
      <div class="row">
        <div class="col-12" v-if="terminosCondiciones">
          <div v-html="terminosCondiciones.texto">

          </div>

            <!-- <div style="text-align: center;">
                <h2>Sobre la plataforma “Lógiga Escuela de Negocios”</h2>
            </div>
             <div style="text-align: justify;" class="mt-3">
               <p>Estos términos y condiciones de uso han sido actualizados el 23 de febrero de 2023.</p>
               <br>
                <h4>INFORMACIÓN RELEVANTE</h4>
                <p>
                    Es requisito necesario para la adquisición de los productos que se ofrecen en este sitio, que lea y acepte los siguientes Términos y Condiciones que a continuación se redactan. El uso de nuestros servicios así como la compra de nuestros productos implicará que usted ha leído y aceptado los Términos y Condiciones de Uso en el presente documento. Todas los productos  que son ofrecidos por nuestro sitio web pudieran ser creadas, cobradas, enviadas o presentadas por una página web tercera y en tal caso estarían sujetas a sus propios Términos y Condiciones. En algunos casos, para adquirir un producto, será necesario el registro por parte del usuario, con ingreso de datos personales fidedignos y definición de una contraseña.
                </p>
               <br>
               <p>
                    El usuario puede elegir y cambiar la clave para su acceso de administración de la cuenta en cualquier momento, en caso de que se haya registrado y que sea necesario para la compra de alguno de nuestros productos.  "Lógiga Escuela de Negocios" no asume la responsabilidad en caso de que entregue dicha clave a terceros.
               </p>
               <br>
               <p>
                    Todas las compras y transacciones que se lleven a cabo por medio de este sitio web, están sujetas a un proceso de confirmación y verificación, el cual podría incluir la verificación del stock y disponibilidad de producto, validación de la forma de pago, validación de la factura (en caso de existir) y el cumplimiento de las condiciones requeridas por el medio de pago seleccionado. En algunos casos puede que se requiera una verificación por medio de correo electrónico.
                    Los precios de los productos ofrecidos en esta Tienda Online es válido solamente en las compras realizadas en este sitio web.
               </p>
               <br>

               <h4>USO NO AUTORIZADO</h4>
               <p>
                    En caso de que aplique (para venta de software, templetes, u otro producto de diseño y programación) usted no puede colocar uno de nuestros productos, modificado o sin modificar, en un CD, sitio web o ningún otro medio y ofrecerlos para la redistribución o la reventa de ningún tipo.
               </p>
               <br>

               <h4>PROPIEDAD</h4>
                <p>
                    Usted no puede declarar propiedad intelectual o exclusiva a ninguno de nuestros productos, modificado o sin modificar. Todos los productos son propiedad  de los proveedores del contenido. En caso de que no se especifique lo contrario, nuestros productos se proporcionan  sin ningún tipo de garantía, expresa o implícita. En ningún esta compañía será  responsables de ningún daño incluyendo, pero no limitado a, daños directos, indirectos, especiales, fortuitos o consecuentes u otras pérdidas resultantes del uso o de la imposibilidad de utilizar nuestros productos.
                </p>
               <br>

               <h4>COMPROBACIÓN ANTIFRAUDE</h4>
               <p>
                    La compra del cliente puede ser aplazada para la comprobación antifraude. También puede ser suspendida por más tiempo para una investigación más rigurosa, para evitar transacciones fraudulentas.
               </p>
               <br>

               <h4>PRIVACIDAD</h4>
               <p>
                    Este sitio garantiza que la información personal que usted envía cuenta con la seguridad necesaria. Los datos ingresados por usuario o en el caso de requerir una validación de los pedidos no serán entregados a terceros, salvo que deba ser revelada en cumplimiento a una orden judicial o requerimientos legales.

                    La suscripción a boletines de correos electrónicos publicitarios es voluntaria y podría ser seleccionada al momento de crear su cuenta.

                     "Lógiga Escuela de Negocios" reserva los derechos de cambiar o de modificar estos  sin previo aviso.
               </p>
               <br>

              <p>
                  Actualizado al 23 de febrero de 2023.
              </p>
             </div> -->
        </div>
      </div>
   </div>
  </section>

  <Footer footerPadding="true"/>
</template>

<script>
import Header from '../components/Home/Header.vue';
import Breadcrumb from '../components/common/Breadcrumb.vue';
import Footer from '../components/Home/Footer.vue';

import axios from "@/axios";

export default {
   name:'TerminosCondiciones',
   components:{
		Header,
      Breadcrumb,

      Footer,
	},
  data () {
    return {
        terminosCondiciones: null,
        mostrarLoader: false
    }
  },
  methods:{
    getTerminosCondiciones () {
        this.openLoadingInDiv()
        axios.get('api/curso/terminos_condiciones').then(response => {
            this.terminosCondiciones = response.data.data;
            this.closeLoadingInDiv()
            }
        );
    },
    openLoadingInDiv(){
        this.mostrarLoader = true
    },
    closeLoadingInDiv(){
        this.mostrarLoader = false
    }
    },
    mounted() {
      this.getTerminosCondiciones()
    },
}
</script>
