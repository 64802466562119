<template>
<section class="counter__area pt-90 pb-100 grey-bg">
   <div class="container">
      <div class="row align-items-center justify-content-center">
         <div class="col-xxl-8 col-xl-8">
            <div class="section__title-wrapper mb-60">
                <div class="text-center">
                  <h2 class="section__title">Objetivos <span class="yellow-bg yellow-bg-big">Institucionales<img src="../../assets/img/shape/yellow-bg.png" alt=""></span></h2>
                </div>
                <br>
                <ol>
                    <li><p style="text-align: justify; font-size: 18px;">&#x2022; Atraer y capacitar talento en los Operadores de Comercio Exterior del mundo mediante una correcta política de gestión del talento humano.</p></li>
                    <li><p style="text-align: justify; font-size: 18px;">&#x2022; Aportar al mercado Laboral con funcionarios de carrera preparados en materia aduanera y comercio exterior.</p></li>
                    <li><p style="text-align: justify; font-size: 18px;">&#x2022; Que nuestros clientes tengan un alto y estricto marco Ético, cuyos valores estén puestos en el desarrollo de sus naciones.</p></li>
                    <li><p style="text-align: justify; font-size: 18px;">&#x2022; Implementar sistemas de gestión del conocimiento que fomenten la creación y el intercambio de experiencias y aprendizajes a fin de que las empresas no pierdan la memoria organizacional, no se repitan los errores y se transmita el conocimiento entre su distintas areas, y las nuevas generaciones.</p></li>
                    <li><p style="text-align: justify; font-size: 18px;">&#x2022; Comunicar de forma planificada, coherente y coordinada para seguir elevando el perfil y la visibilidad de las operaciones aduaneras, fomentando siempre entre el personal el orgullo profesional de pertenecer al sector logístico.</p></li>
                    <li><p style="text-align: justify; font-size: 18px;">&#x2022; Redimensionar el valor de las aduanas en el pais, para devolverle a la aduana su sentido primario y estratégico de impulsora de los objetivos nacionales de desarrollo económico, fiscal social y de seguridad a través del comercio exterior. Esta redimensión de la aduana tiene que ser aprovechado por todos sus actores para colaborar a la seguridad y la competitividad nacional.</p></li>
                </ol>
            </div>
         </div>
      </div>
   </div>
</section>
</template>

<script>
export default {
   name:'ObjetivosArea'
};
</script>
