<template>
<div class="tab-pane fade" id="member" role="tabpanel" aria-labelledby="member-tab">
   <div class="course__member mb-45">
      <div class="course__member-item" v-for="teacher in teachers" :key="teacher.token">
         <div class="row align-items-center">
            <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-6">
               <div class="course__member-thumb d-flex align-items-center">
                 <div class="course__teacher-thumb-3">
                  <img :src="teacher.avatar_image_url" alt="">
                 </div>
                  <div class="course__member-name ml-20">
                     <h5>{{ teacher.display_name }}</h5>
                     <span>Docente</span>
                  </div>
               </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-5">
               <div class="course__member-info pl-45">
                  <h5>{{ teacher.cantidad_cursos }}</h5>
                  <span>Cursos</span>
               </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-5">
               <div class="course__member-info pl-70">
                  <h5>{{ teacher.fecha_creacion }}</h5>
                  <span>Desde</span>
               </div>
            </div>
<!--            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4">-->
<!--               <div class="course__member-info pl-85">-->
<!--                  <h5>3.00</h5>-->
<!--                  <span>Rating</span>-->
<!--               </div>-->
<!--            </div>-->
         </div>
      </div>
   </div>
 </div>
</template>

<script>
export default {
   name:'MemberTab',
    props: {
        teachers: Array,
    },
};
</script>
