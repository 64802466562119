<template>
 <section class="why__area pt-50 pb-50 grey-bg">
   <div class="container">
      <div class="row align-items-center">
         <div class="col-xxl-6 offset-xl-3 col-xl-6 offset-xl-3">
            <div class="why__content pr-50 mt-40">
               <div class="section__title-wrapper mb-30 text-center">
<!--                  <span class="section__sub-title">Why Choses Me</span>-->
<!--                  <h2 class="section__title">Tools for <span class="yellow-bg yellow-bg-big">Teachers<img src="../../assets/img/shape/yellow-bg.png" alt=""></span> and Learners</h2>-->

                  <h2 class="section__title"><span class="yellow-bg yellow-bg-big">Misión<img src="../../assets/img/shape/yellow-bg.png" alt=""></span></h2>
                  <p style="text-align: justify; font-size: 18px;">
                      Ser un centro de capacitación reconocido por la comunidad siendo la promotora que genere soluciones y mejores sus condiciones socio económicas de sus estudiantes con la formación continua, asegurando profesionales de éxitos con responsabilidad social.
                  </p>
               </div>
<!--               <div class="why__btn">-->
<!--                  <router-link to="/contact" class="e-btn e-btn-3 mr-30">Join for Free</router-link>-->
<!--                  <router-link to="/about" class="link-btn">-->
<!--                     Learn More-->
<!--                     <i class="far fa-arrow-right"></i>-->
<!--                     <i class="far fa-arrow-right"></i>-->
<!--                  </router-link>-->
<!--               </div>-->
            </div>
         </div>
<!--         <div class="col-xxl-5 col-xl-5 col-lg-6 col-md-8">-->
<!--            <div class="why__thumb d-flex justify-content-end">-->
<!--               <img src="../../assets/img/logiga/foto_1_576x470.jpg" alt="" width="407" style="border-radius: 2%;">-->
<!--&lt;!&ndash;               <img class="why-green" src="../../assets/img/why/why-shape-green.png" alt="">&ndash;&gt;-->
<!--&lt;!&ndash;               <img class="why-pink" src="../../assets/img/why/why-shape-pink.png" alt="">&ndash;&gt;-->
<!--&lt;!&ndash;               <img class="why-dot" src="../../assets/img/why/why-shape-dot.png" alt="">&ndash;&gt;-->
<!--&lt;!&ndash;               <img class="why-line" src="../../assets/img/why/why-shape-line.png" alt="">&ndash;&gt;-->
<!--            </div>-->
<!--         </div>-->
      </div>
   </div>
</section>
</template>

<script>
export default {
   name:'WhyArea'
};
</script>

