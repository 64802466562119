<template>
 <footer>
   <div :class="`footer__area ${footerBgTwo ? 'grey-bg-2' : 'footer-bg'}`">
      <div :class="`footer__top ${footerPadding ? 'pt-90' : 'pt-190'} pb-40`">
         <div class="container">
            <div class="row">
               <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6">
                  <div class="footer__widget mb-50">
                     <div class="footer__widget-head mb-22">
                        <div class="footer__logo">
                           <router-link to="/">
                              <img :src="`${footerBgTwo ? require('../../assets/img/logo/logiga-escuela-long-logo.png') : require('../../assets/img/logo/logiga-escuela-long-logo-white.png')}`" alt="" style="width: 150px; height: 40px;">
                           </router-link>
                        </div>
                     </div>
                     <div :class="`${footerBgTwo ? 'footer__widget-body-2' : 'footer__widget-body'}`">
<!--                        <p>Great lesson ideas and lesson plans for ESL teachers! Educators can customize lesson plans to best.</p>-->

                        <div class="footer__social">
                           <ul>
                              <li><a href="#" class="fb"><i class="fab fa-facebook-f social_facebook"></i></a></li>
                              <li><a href="#" class="pin"><i class="fab fa-instagram social_pinterest"></i></a></li>
                              <li><a href="https://twitter.com/logigaescuela/" class="tw" target="_blank"><i class="fab fa-twitter social_twitter"></i></a></li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xxl-2 offset-xxl-1 col-xl-2 offset-xl-1 col-lg-3 offset-lg-0 col-md-2 offset-md-1 col-sm-5 offset-sm-1">
                  <div class="footer__widget mb-50">
                     <div class="footer__widget-head mb-22">
                        <h3 :class="`${footerBgTwo ? 'footer__widget-title-2' : 'footer__widget-title'}`">Acerca de</h3>
                     </div>
                     <div class="footer__widget-body">
                        <div :class="`${footerBgTwo ? 'footer__link-2' : 'footer__link'}`">
                           <ul>
                              <li><router-link to="/cursos">Cursos</router-link></li>
                              <li><router-link to="/programas">Programas</router-link></li>
                              <li><router-link to="/blogs">Blog</router-link></li>
                              <li><router-link to="/nosotros">Nosotros</router-link></li>
                              <li><router-link to="/contacto">Contacto</router-link></li>
<!--                              <li><a href="#">About</a></li>-->
<!--                              <li><a href="#">Courses</a></li>-->
<!--                              <li><a href="#">Events</a></li>-->
<!--                              <li><a href="#">Instructor</a></li>-->
<!--                              <li><a href="#">Career</a></li>-->
<!--                              <li><a href="#">Become a Teacher</a></li>-->
<!--                              <li><a href="#">Contact</a></li>-->
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xxl-2 col-xl-2 col-lg-2 offset-lg-0 col-md-3 offset-md-1 col-sm-6">
                  <div class="footer__widget mb-50">
                     <div class="footer__widget-head mb-22">
                        <h3 :class="`${footerBgTwo ? 'footer__widget-title-2' : 'footer__widget-title'}`">Enlaces de interés</h3>
                     </div>
                     <div class="footer__widget-body">
                        <div :class="`${footerBgTwo ? 'footer__link-2' : 'footer__link'}`">
                           <ul>
                              <li><router-link to="/terminos-y-condiciones">Términos y Condiciones</router-link></li>
                              <li><router-link to="/politicas-privacidad">Políticas de Privacidad</router-link></li>
<!--                              <li><a href="#">Browse Library</a></li>-->
<!--                              <li><a href="#">Library</a></li>-->
<!--                              <li><a href="#">Partners</a></li>-->
<!--                              <li><a href="#">News & Blogs</a></li>-->
<!--                              <li><a href="#">FAQs</a></li>-->
<!--                              <li><a href="#">Tutorials</a></li>-->
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-5 col-sm-6">
                  <div class="footer__widget footer__pl-70 mb-50">
                     <div class="footer__widget-head mb-22">
                        <h3 :class="`${footerBgTwo ? 'footer__widget-title-2' : 'footer__widget-title'}`">Noticias</h3>
                     </div>
                     <div class="footer__widget-body">
                        <div :class="`${footerBgTwo ? 'footer__subscribe-2' : 'footer__subscribe'}`">
                           <form action="#">
                              <div class="footer__subscribe-input mb-15">
                                 <input type="email" placeholder="Tu correo">
                                 <button type="submit">
                                    <i class="far fa-arrow-right"></i>
                                    <i class="far fa-arrow-right"></i>
                                 </button>
                              </div>
                           </form>
                           <p>Manténte actualizado con nuestras últimas noticias.</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div :class="`${footerBgTwo ? 'footer__bottom footer__bottom-2' : 'footer__bottom'}`">
         <div class="container">
            <div class="row">
               <div class="col-xxl-12">
                  <div :class="`${footerBgTwo ? 'footer__copyright-2 text-center' : 'footer__copyright text-center'}`">
                     <p>Copyright © {{ new Date().getFullYear() }} <router-link to="/">Lógiga Escuela de Negocios</router-link>. All rights Reserved.</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</footer>
</template>

<script>
export default {
   name:'FooterArea',
   props:{
      footerBgTwo:String,
      footerPadding:String,
   }
};
</script>
