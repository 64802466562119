<template>
  <loading
      v-model:active="mostrarLoader"
      color="#0F80C1"
      loader="dots"
  />
  <Header headerShadow="header__area header__padding header__padding-2 header__shadow " />

  <section class="pt-90 pb-50">
   <div class="container">
      <div class="row">
        <div class="col-12 d-flex justify-content-center">
          <div v-if="orden && orden.metodo_pago=='BAN' && !orden.sincronizado_pago && orden.evidencias == 0" style="width: 75%;">
            <div class="your-order mt-30 mb-30" style="text-align: center;">
                <h2>Orden #{{ orden.codigo }}</h2>
                <div id="datos_facturacion" class="mb-40">
                    <p>{{ orden.datos_facturacion.nombres }} {{ orden.datos_facturacion.apellidos }}</p>
                    <p><b>C.C.</b> {{ orden.datos_facturacion.documento_identidad }} <b>Teléf.</b> {{ orden.datos_facturacion.telefono }}</p>
                    <p><b>Email</b> <a :href="'mailto:'+orden.datos_facturacion.email" style="color:#0F80C1">{{ orden.datos_facturacion.email }}</a></p>
                    <p>{{ orden.datos_facturacion.direccion }}, {{ orden.datos_facturacion.ciudad }}, {{ orden.datos_facturacion.pais }}</p>
                </div>

                <div class="your-order-table table-responsive">
                    <table>
                       <thead>
                          <tr>
                             <th class="product-name"><b>Detalles</b></th>
                             <th class="product-total" style="text-align: right;"><b>Total</b></th>
                          </tr>
                       </thead>
                       <tbody>
                          <tr class="cart_item" v-for="detalle in orden.detalles_orden.filter((detalle) => detalle.facturable)" :key="detalle.nombre">
                             <td class="product-name" style="padding-left: 30px;" v-if="detalle.curso">
                                {{ detalle.curso.name }} {{ detalle.modalidad_seleccionada }}<strong class="product-quantity"> × 1</strong>
                             </td>
                             <td class="product-name" style="padding-left: 30px;" v-else-if="detalle.programa">
                                {{ detalle.programa.name }} {{ detalle.modalidad_seleccionada }}<strong class="product-quantity"> × 1</strong>
                             </td>
                             <td class="product-total" style="text-align: right;">
                                <span class="amount">$ {{ detalle.precio_postdescuento.toFixed(2) }}</span>
                             </td>
                          </tr>
                       </tbody>
                       <tfoot>
                          <tr class="cart-subtotal">
                             <th>Subtotal</th>
                             <td style="text-align: right;"><span class="amount"><b>$ {{ orden.subtotal.toFixed(2) }}</b></span></td>
                          </tr>
                          <tr class="order-impuestos">
                             <th>Impuestos (15% IVA)</th>
                             <td style="text-align: right;"><strong><span class="amount"><b>$ {{ orden.impuestos.toFixed(2) }}</b></span></strong>
                             </td>
                          </tr>
                          <tr class="order-total">
                             <th>Total</th>
                             <td style="text-align: right;"><strong><span class="amount">$ {{ orden.total.toFixed(2) }}</span></strong>
                             </td>
                          </tr>
                       </tfoot>
                    </table>
                </div>
            </div>

            <div class="your-order mt-60 mb-30" style="text-align: center;">
                <h2>Comprobante de Pago</h2>
              <p>Para completar el pago, realice una transferencia o depósito bancario a la siguiente cuenta: </p>
              <div class="text-center mt-4">
                  <b>Banco Pichincha Cuenta Corriente #2100287513</b><br>
                  <b>Compañía: </b>Lógiga Escuela de Negocios S.A.<br>
                  <b>R.U.C.: </b>0993374041001<br>
                  <b>Correo: </b> contabilidad@logigacorp.com<br>
              </div>
              <div class="text-center mt-2">
                  <a href="#">
                     <img src="@/assets/img/course/payment/banco-pichincha.png" alt="banco pichincha" height="30" width="120">
                     <img src="@/assets/img/course/payment/cash-payment.png" alt="cash" height="40" width="70">
                  </a>
              </div>

              <br><p>A continuación, cargue el comprobante de pago entre otras evidencias. Solo se permiten archivos PDF e imágenes JPG y PNG.</p>
                <div class="mb-60">
                  <input id="upload_file" type="file" accept=".jpg,.png,.pdf" @change="onUploadFile" multiple>

                  <div>
                    <div v-if="files_evidencias.length > 0" class="files mt-20">
                      <div class="file-item" v-for="(file, index) in files_evidencias" :key="index">
                        <span>{{ file.name }}</span>
                        <span class="delete-file" @click="handleClickDeleteFile(index)"
                          >Eliminar</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" class="e-btn e-btn-border" @click="guardarFile">Enviar</button>
            </div>
          </div>

          <div v-else-if="orden && orden.metodo_pago=='BAN' && !orden.sincronizado_pago && orden.evidencias > 0" class="mt-75 mb-75">
            <div style="text-align: center;">
                <p>Ya fue enviado el comprobante de pago y evidencias correspondientes a esta orden.</p>
            </div>
          </div>

          <div v-else class="mt-75 mb-75">
            <div style="text-align: center;">
                <p >No existe información para mostrar</p>
            </div>
          </div>

        </div>
      </div>
   </div>
  </section>

  <Footer footerPadding="true"/>
</template>

<script>
import Header from '../components/Home/Header.vue';
import Footer from '../components/Home/Footer.vue';
import axios from "@/axios";

export default {
     name:'EvidenciaPagoPage',
     components:{
        Header,
        Footer,
    },
    data() {
        return {
            orden: null,
            token_orden: this.$route.params.token,
            mostrarLoader: false,
            files_evidencias: [],
            limite_file: 1024
        }
    },
    methods: {
       obtenerOrden(){
          this.openLoadingInDiv()
          axios.get(`api/orden/${this.token_orden}/`).then(response => {
              this.orden = response.data
              this.closeLoadingInDiv()
          });
       },

        openLoadingInDiv(){
            this.mostrarLoader = true
        },
        closeLoadingInDiv(){
            this.mostrarLoader = false
        },

        onUploadFile(event){
            const selectedFiles = event.target.files;
            for (const file of selectedFiles){
                if (Math.round((file.size / this.limite_file)) > this.limite_file) {
                    this.$swal({
                      title: 'Error!',
                      text: 'Tamaño de archivo no permitido. El peso máximo es de 1MB.',
                      icon: 'error',
                      confirmButtonColor: '#0F80C1',
                      confirmButtonText: 'Aceptar',
                    })
                    return false
                }
                else if(this.checkFileExtension(file.name)){
                    this.$swal({
                      title: 'Error!',
                      text: 'Formato de archivo no permitido. Solo se acepta JPG y PNG.',
                      icon: 'error',
                      confirmButtonColor: '#0F80C1',
                      confirmButtonText: 'Aceptar',
                    })
                    return false
                }
                else{
                    this.createBase64File(file);
                }
            }
            document.getElementById("upload_file").value = '';
        },
        checkFileExtension(filename){
            filename = filename.toLowerCase();
            const ext = filename.split('.').pop();
            if(ext!='png' && ext!='jpg' && ext!='jpeg' && ext!='pdf'){
                return true
            }
            return false
        },
        createBase64File(fileObject){
            const reader = new FileReader();
            reader.onload = (e) => {
                let newFile = {'name': fileObject.name, 'data': e.target.result}
                this.files_evidencias.push(newFile)
            };
            reader.readAsDataURL(fileObject);
        },

        guardarFile(){
          if(this.files_evidencias.length > 0) {
            this.openLoadingInDiv();
            axios
                .post('api/orden/subir-comprobante/',
                    {
                      orden: this.$route.params.token,
                      files_evidencias: this.files_evidencias
                    }
                ).then(response => {
              if ("error" == response.data.status) {
                this.$swal({
                  title: 'Error!',
                  text: response.data.message,
                  icon: 'error',
                  confirmButtonColor: '#0F80C1',
                  confirmButtonText: 'Aceptar',
                })
                this.closeLoadingInDiv();
              } else {
                this.$swal({
                  title: 'Información guardada con éxito.',
                  text: response.data.message,
                  icon: 'success',
                  confirmButtonColor: '#0F80C1',
                  confirmButtonText: 'Aceptar',
                }).then(result => {
                    console.log(result)
                    this.$router.push("/")
                })
                this.closeLoadingInDiv();
              }
            });
          }
          else{
              this.$swal({
                title: 'Error!',
                text: 'No ha subido ningún archivo.',
                icon: 'error',
                confirmButtonColor: '#0F80C1',
                confirmButtonText: 'Aceptar',
              })
          }
        },
        handleClickDeleteFile(index){
            this.files_evidencias.splice(index, 1)
        }
    },
    mounted() {
        this.obtenerOrden()
    }
}
</script>

<style>
  #datos_facturacion p{
      margin-bottom: 0px;
      font-size: 18px;
  }

  .dropzone,
  .files {
    width: 100%;
    max-width: 75%;
    margin: 0 auto;
    padding: 10px;
    border-radius: 8px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    font-size: 12px;
    line-height: 1.5;
  }

  .file-item {
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /*background: rgb(255 167 18 / 20%);*/
    padding: 7px;
    padding-left: 15px;
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }

    .delete-file {
      background: red;
      color: #fff;
      padding: 5px 10px;
      border-radius: 8px;
      cursor: pointer;
    }
  }
</style>
