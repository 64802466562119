<template>
 <router-view/>
   <div>
      <a href="https://api.whatsapp.com/send?phone=+593939661781&text=Hola, deseo mayor información sobre Lógiga Escuela de Negocios." class="float" target="_blank">
         <i class="fab fa-whatsapp my-float"></i>
      </a>
   </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
}
</style>


