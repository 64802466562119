<template>
   <Header header__white="header__white" />
   <Breadcrumb title="Programas" subtitle="Programas" />
   <Programas/>
   <Footer footerPadding="true"/>
</template>

<script>
import Header from '../components/Home/Header.vue';
import Breadcrumb from '../components/common/Breadcrumb.vue';
import Programas from '../components/Courses/ProgramasArea.vue';
import Footer from '../components/Home/Footer.vue';

export default {
   name:'ProgramasPage',
   components:{
      Header,
      Breadcrumb,
      Programas,
      Footer
   }
};
</script>
