<template>
   <loading
      v-model:active="mostrarLoader"
      color="#0F80C1"
      loader="dots"
   />

   <BlogDetailsTitle :title="dataBlog.title" :fecha="dataBlog.date"/>
   <section class="blog__area pt-120 pb-80">
      <div class="container">
         <div class="row">
            <!-- <div class="col-xxl-8 col-xl-8 col-lg-8"> -->
               <div class="col-12">
               <div class="blog__wrapper">
                  <div class="blog__text mb-40 custom-text-justify" v-html="dataBlog.body">
                  </div>
                  <!-- <div class="blog__quote grey-bg mb-45 p-relative fix">
                     <img class="quote" src="../../assets/img/blog/quote-1.png" alt="">
                     <blockquote>
                        <p>After I started learning design with Quillow, I realized that I had Improved to very advanced levels.</p>
                        <h4>Chris Collins</h4>
                     </blockquote>
                  </div>
                  <div class="blog__text mb-30">
                     <p>Horse play it's all gone to pot codswallop easy peasy mush knees up down the pub jolly good nice one tosser it's your round lurgy, I vagabond barmy off his nut only a quid so I said is gosh Charles blow off, pardon me chip shop Richard spiffing skive off bleeding get stuffed mate porkies amongst the full monty. Daft burke you</p>
                  </div>
                  <div class="blog__link mb-35">
                     <p>The little rotter bum bag a blinding shot gosh spifing butty eatonwha load of rubbish bamboozled. <a href="#"> https://educal.com/courses</a> </p>
                  </div>
                  <div class="blog__img w-img mb-45" >
                     <img src="../../assets/img/blog/big/blog-big-1.jpg" alt="">
                  </div>
                  <div class="blog__text mb-40">
                     <h3>Build a beautiful website with Quillow</h3>
                     <p>Some dodgy chav car boot blower starkers bonnet tickety-boo no biggie cheesed off, Oxford bloke fantastic the wireless bevvy cobblers chancer give us a bell, bleeder jolly good hanky panky do one gormless matie boy. Pear shaped my good sir I cobblers at public school quaint tickety-boo crikey bits and bobs, wellies bugger all mate golly gosh brolly matie boy fanny around chimney pot cracking goal my lady, bodge so I said spiffing posh the full monty don't get shirty with me no biggie.</p>

                     <p>Brolly grub blimey victoria sponge blag nancy boy don't get shirty with me skive off bobby burke in my flat bog-standard, easy peasy golly gosh up the duff show off show off pick your nose and blow off gosh a brilliant that what a load of rubbish.</p>
                  </div> -->

                  <div class="container" v-if="dataBlog.ebook">
                     <div class="row justify-content-center">
                       <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                         <div
                           class="banner__item p-relative mb-40"
                           :style="{background:'url('+ require('../../assets/img/banner/banner-bg-2.jpg')+')',backgroundRepeat:'no-repeat',backgroundSize:'cover'}"
                         >
                           <div class="banner__content">
                             <span class="orange">Nuevo</span>
                             <h3 class="banner__title">
                               <router-link to="#" v-b-modal.ebook-modal-center>¡Descarga nuestro <br />Ebook gratuito! </router-link>
                             </h3>
                             <!-- <router-link to="/courses" class="e-btn e-btn-2">Find Out More</router-link> -->
                             <button v-b-modal.ebook-modal-center data-fancybox="" class="e-btn e-btn-2"> Descargar </button>
             
                           </div>
                           <div
                             class="
                               banner__thumb banner__thumb-2
                               d-none d-sm-block d-md-none d-lg-block
                             "
                           >
                             <img src="../../assets/img/banner/banner-img-2.png" alt="" />
                           </div>
                         </div>
                       </div>
                     </div>
                  </div>

                  <div class="blog__line"></div>
                  <div class="blog__meta-3 d-sm-flex justify-content-between align-items-center mb-30">
                     <!-- <div class="blog__tag-2">
                        <a href="#">Art & Design</a>
                        <a href="#">Education</a>
                        <a href="#">App</a>
                     </div> -->
                     <!-- <div class="blog__social d-flex align-items-center">
                        <h4>Share:</h4>
                        <ul>
                           <li><a href="#" class="fb" >
                              <i class="fab fa-facebook-f"></i>
                              </a></li>
                           <li><a href="#" class="tw" >
                              <i class="fab fa-twitter"></i>
                              </a></li>
                           <li><a href="#" class="pin" >
                              <i class="fab fa-pinterest-p"></i>
                              </a></li>
                        </ul>
                     </div> -->
                  </div>

                  <div class="blog__author-3 d-sm-flex grey-bg flex-column" v-if="dataBlog.authors && dataBlog.authors.length>0">
                     <div v-for="author in dataBlog.authors" :key="author.name">
                        <div class="blog__author-thumb-3 mr-20">
                           <img :src="author.author_image_url" alt="">
                        </div>
                        <div class="blog__author-content">
                           <h4>{{ author.name }}</h4>
                           <span>Autor</span>
                        </div>
                     </div>
                     <p>Somos un grupo de practicantes y Expertos en Comercio Exterior comprometidos con darte soluciones prácticas y alcanzables.</p>
                  </div>
                  <!-- <div class="blog__recent mb-65">
                     <div class="section__title-wrapper mb-40">
                        <h2 class="section__title">Related <span class="yellow-bg-sm">Post <img src="../../assets/img/shape/yellow-bg-4.png" alt="">  </span></h2>
                        <p>You don't have to struggle alone, you've got our assistance and help.</p>
                     </div>
                     <div class="row">
                        <div v-for="blog in blogItems.slice(0,2)" :key="blog.id" class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                           <div class="blog__item white-bg mb-30 transition-3 fix">
                              <div class="blog__thumb w-img fix">
                                 <router-link :to="`/blog-details/${blog.id}`">
                                    <img :src="blog.blogImg" alt="">
                                 </router-link>
                              </div>
                              <div class="blog__content">
                                 <div class="blog__tag">
                                    <a href="#" :class="blog.color">{{blog.category}}</a>
                                 </div>
                                 <h3 class="blog__title">
                                    <router-link :to="`/blog-details/${blog.id}`">
                                    {{blog.title}}</router-link>
                                 </h3>
                                 <div class="blog__meta d-flex align-items-center justify-content-between">
                                    <div class="blog__author d-flex align-items-center">
                                       <div class="blog__author-thumb mr-10">
                                          <img :src="blog.authorImg" alt="">
                                       </div>
                                       <div class="blog__author-info">
                                          <h5>{{blog.authorName}}</h5>
                                       </div>
                                    </div>
                                    <div class="blog__date d-flex align-items-center">
                                       <i class="fal fa-clock"></i>
                                       <span>{{blog.date}}</span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div> -->

                  <!-- latest comment start -->
                  <!-- <LatestComment/> -->
                  <!-- latest comment end -->

                  <!-- blog comment start -->
                  <!-- <BlogWriteComment/> -->
                  <!-- blog comment end -->
               </div>
            </div>
            <!-- BlogRightSide start -->
            <!-- <BlogRightSide/> -->
            <!-- BlogRightSide end -->
         </div>
      </div>
    </section>

  <!-- modal  start-->
   <div v-if="dataBlog.ebook">
      <div class="video-modal-wrapper">
         <b-modal id="ebook-modal-center" centered ref="downloadEbookModal">
            <div class="container">
               <div class="text-center"><h4>{{ dataBlog.ebook.name }}</h4></div>
               

               <Form @submit="checkSubmitDownloadForm" :validation-schema="checkoutSchema">
                  <div class="row mt-20">
                     <div class="col-12">
                        <div class="checkbox-form">
                           <div class="row">
                              <div class="col-12">
                                 <div class="checkout-form-list">
                                    <Field name="nombres" v-slot="{ field, errors, errorMessage }">
                                       <label>Nombres <span class="required">*</span></label>
                                       <input type="text" placeholder="Ingrese sus nombres..." v-model="downloadEbook.nombres" v-bind="field" class="form-control" :class="[{'is-invalid': errors.length>0}]" maxlength="100"/>
                                       <span class="text-danger">{{ errorMessage }}</span>
                                    </Field>
                                 </div>
                              </div>

                              <div class="col-12">
                                 <div class="checkout-form-list">
                                    <Field name="email" v-slot="{ field, errors, errorMessage }">
                                       <label>Email <span class="required">*</span></label>
                                       <input type="text" placeholder="Ingrese su email..." v-model="downloadEbook.correo" v-bind="field" class="form-control" :class="[{'is-invalid': errors.length>0}]" maxlength="100"/>
                                       <span class="text-danger">{{ errorMessage }}</span>
                                    </Field>
                                 </div>
                              </div>

                              <div class="mt-10 d-flex justify-content-center">
                                 <button type="submit" class="e-btn e-btn-border">Descargar</button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </Form>
            </div>
         </b-modal>
      </div>
   </div>
<!-- modal  end-->
</template>

<script>
import BlogDetailsTitle from './BlogDetailsTitle.vue';
// import BlogRightSide from '../Blog/BlogRightSide.vue';
// import LatestComment from './LatestComment.vue';
// import BlogWriteComment from './BlogWriteComment.vue';
import BlogMixin from '../../mixins/blogMixin';
import axios from '@/axios';
import { Field, Form } from 'vee-validate';

export default {
   name:'BlogDetailsArea',
   mixins:[BlogMixin],
   components:{
      BlogDetailsTitle,
      Form,
      Field
      // BlogRightSide,
      // BlogWriteComment,
      // LatestComment,
   },
   data() {
      return {
         dataBlog: {},
         token: this.$route.params.token,
         mostrarLoader: false,
         checkoutSchema: {
            nombres: 'required',
            email: 'required',
         },
         downloadEbook: {
            nombres: '',
            correo: ''
         }
      }
   },
   methods: {
      getBlog(){
         // this.openLoadingInDiv()
         axios.get(`wagtail-api/pages/${this.token}/`).then(response => {
            this.dataBlog = response.data;
            // this.closeLoadingInDiv()
         })
      },

      checkSubmitDownloadForm(){
         this.openLoadingInDiv()
         axios.post('api/ebook/descargar-ebook/',{
               nombre: this.downloadEbook.nombres,
               correo: this.downloadEbook.correo,
               ebook: this.dataBlog.ebook.id
         },{
            responseType: 'blob'
         }).then(response => {
            this.closeLoadingInDiv()

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',  this.dataBlog.ebook.name + '.pdf');
            document.body.appendChild(link);
            link.click()

            this.downloadEbook = {
               nombres: '',
               correo: ''
            }

            // this.$refs.downloadEbookModal.hide()
         });
      },

      openLoadingInDiv(){
          this.mostrarLoader = true
      },
      closeLoadingInDiv(){
          this.mostrarLoader = false
      },
   },
   created() {
      this.getBlog();
   }
};
</script>

<style>
   .custom-text-justify p {
      text-align: justify!important;
   }

   .full-width{
      width: 100%!important;
      height: 100%!important;
   }

   #ebook-modal-center .modal-content {
      background-color: #ffffff; /* White background */
      border-radius: 8px; /* Rounded corners */
      padding: 20px; /* Optional: add some padding */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add a shadow for better visual separation */
   }
</style>
