<template>
  <Header header__white="header__white" />
  <Breadcrumb title="Políticas de Privacidad" subtitle="Políticas de Privacidad" />

  <section class="pt-90 pb-50">
   <div class="container">
      <div class="row">
        <div class="col-12">
            <div style="text-align: center;">
                <h2>Sobre la plataforma “Lógiga Escuela de Negocios”</h2>
            </div>
             <div style="text-align: justify;" class="mt-3">
               <p>Estas políticas han sido actualizadas el 28 de febrero de 2023.</p>
               <br>

                <h3>INTRODUCCIÓN</h3>
                <p>
                    Lógiga-Escuela se compromete a proteger la información que procesa haciendo todo lo posible para garantizar que esta se utilice solo para ayudar a los estudiantes e impulsar su educación. En esta misión, nos guiamos por nuestros principios de privacidad:
                </p>
                <ol>
                  <li><b>1. Transparencia:</b> trabajaremos para garantizar el procesamiento transparente de datos en nuestra empresa.</li>
                  <li><b>2. Responsabilidad:</b> le mostraremos nuestro compromiso con la privacidad mediante formas concretas y tangibles.</li>
                  <li><b>3. Integridad:</b> nos esforzaremos por garantizar que los datos proporcionados sean completos, coherentes y precisos.</li>
                  <li><b>4. Seguridad:</b> implementaremos y aplicaremos de forma continua las medidas técnicas, administrativas y organizativas adecuadas para proteger los datos de acuerdo con los requisitos normativos.</li>
                  <li><b>5. Confidencialidad:</b> estableceremos y aplicaremos de forma continua políticas, procedimientos y prácticas que limiten el acceso a los datos y ofrezcan protección frente al acceso o la divulgación ilegales o involuntarios.</li>
                </ol>

                <p>
                    Le invitamos a conocer las prácticas de datos de nuestras plataformas que impulsan el aprendizaje continuo.
                </p>

               <br>

               <h3>CONTENIDO</h3>
               <ol>
                 <li>1.	Acerca de nuestros Usuarios y Productos</li>
                 <li>2.	Recopilación de la información.</li>
                 <li>3.	El uso que hacemos de su Información.</li>
                 <li>4.	Divulgación de la información.</li>
                 <li>5.	Privacidad de los menores.</li>
                 <li>6.	Su información, su control.</li>
                 <li>7.	Otra información importante.</li>
                 <li>8.	Seguridad de la Información personal.</li>
                 <li>9.	Información de contacto.</li>
               </ol>

               <br>
               <p>
                    Lógiga-Escuela, y sus filiales y subsidiarias («Lógiga-Escuela» «nosotros» o «nuestro/a») ha preparado este Aviso de privacidad de productos («Aviso») para describir nuestras prácticas de privacidad. Este Aviso regula la información recopilada y procesada por nuestros productos y servicios propios o subcontratados (de forma conjunta, los «Productos»), que se enumera aquí: <a href="https://escuela.logigacorp.com/" target="_blank" style="color:#0F80C1">https://escuela.logigacorp.com/</a>
               </p>

               <br>
               <br>
               <h4>1.	Acerca de nuestros Usuarios y Productos</h4>
                <p>
                    La forma en que utilizamos su información depende de nuestra relación con usted. Proporcionamos nuestros Productos o subcontratados principalmente a Instituciones académicas, como centros de educación primaria y secundaria y de educación superior y a empresas educativas que utilizan nuestros Productos para potenciar la enseñanza, impulsar el aprendizaje, proporcionar oportunidades de desarrollo profesional y mejorar los resultados de los estudiantes.
                </p>
                <p>
                    Si es un usuario final de una Institución académica o una Empresa que utiliza nuestros productos, esto implica que la institución determina cómo se utiliza su información personal. Esto significa que el aviso de privacidad de su institución rige el uso de su información personal. Este aviso de privacidad solo tiene como finalidad aportar transparencia en relación con nuestras prácticas de privacidad.
                </p>
                <p>
                    Hemos proporcionado el siguiente resumen para ayudarle a entender cómo gestionamos la información que procesamos a través de nuestros Productos y la relación entre nuestros usuarios y Productos.
                </p>
               <br>
                <table style="border: none;border-collapse:collapse; text-align: left;">
                    <tbody>
                        <tr>
                            <td style="padding:.75pt .75pt .75pt .75pt;">
                                <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-size:16px;font-family:"Times New Roman",serif;'>Usuarios</span></strong></p>
                            </td>
                            <td style="padding:.75pt .75pt .75pt .75pt;">
                                <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-size:16px;font-family:"Times New Roman",serif;'>Productos</span></strong></p>
                            </td>
                            <td style="padding:.75pt .75pt .75pt .75pt;">
                                <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-size:16px;font-family:"Times New Roman",serif;'>Ejemplos</span></strong></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="background:#F2F2F2;padding:.75pt .75pt .75pt .75pt;">
                                <ul style="margin-bottom:0in;margin-top:0in;" type="disc">
                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Educadores y profesorado</span></li>
                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Instituciones acad&eacute;micas</span></li>
                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Estudiantes</span></li>
                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Progenitores/<br>&nbsp;tutores</span></li>
                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Administradores de las instituciones</span></li>
                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Empleados corporativos</span></li>
                                </ul>
                            </td>
                            <td style="background:#F2F2F2;padding:.75pt .75pt .75pt .75pt;">
                                <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-size:16px;font-family:  "Times New Roman",serif;color:black;'>Canvas de Instructure:</span></strong></p>
                                <ul style="margin-bottom:0in;margin-top:0in;" type="disc">
                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Canvas LMS</span></li>
                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Canvas Studio</span></li>
                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Canvas Catalog</span></li>
                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Canvas Student Pathways</span></li>
                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Canvas Student ePortfolios</span></li>
                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Canvas Network</span></li>
                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Canvas Commons</span></li>
                                </ul>
                            </td>
                            <td style="background:#F2F2F2;padding:.75pt .75pt .75pt .75pt;">
                                <ul style="margin-bottom:0in;margin-top:0in;" type="disc">
                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Los educadores proporcionan contenido e instrucciones a trav&eacute;s de Canvas LMS.</span></li>
                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Los educadores pueden utilizar Canvas LMS para proporcionar informaci&oacute;n individualizada a los estudiantes u ofrecerles espacios de trabajo colaborativos.</span></li>
                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Las Instituciones acad&eacute;micas pueden utilizar Canvas LMS para habilitar, personalizar y configurar la forma en que los educadores y los estudiantes utilizan esta plataforma.</span></li>
                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Los educadores y los estudiantes pueden enviar creaciones en v&iacute;deo con Canvas Studio.</span></li>
                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Los progenitores pueden ver el trabajo acad&eacute;mico de los estudiantes en Canvas LMS.</span></li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
               <br>

                <table style="border: none;border-collapse:collapse; text-align: left;">
                    <tbody>
                        <tr>
                            <td style="padding:.75pt .75pt .75pt .75pt;">
                                <table style="border: none;border-collapse:collapse;">
                                    <tbody>
                                        <tr>
                                            <td style="padding:.75pt .75pt .75pt .75pt;">
                                                <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-size:16px;font-family:  "Times New Roman",serif;'>Mastery de Instructure:</span></strong></p>
                                                <ul style="margin-bottom:0in;margin-top:0in;" type="disc">
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;'>Mastery Connect</span></li>
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;'>Mastery Item Bank&nbsp;</span></li>
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;'>Mastery Item Bank Supplemental</span></li>
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;'>Mastery View College Prep</span></li>
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;'>Mastery View Formative Assessments</span></li>
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;'>Mastery View Interim Assessments</span></li>
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;'>Mastery View Predictive Assessments</span></li>
                                                </ul>
                                            </td>
                                            <td style="padding:.75pt .75pt .75pt .75pt;">
                                                <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                                                <ul style="margin-bottom:0in;margin-top:0in;" type="disc">
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;'>Los educadores pueden utilizar Mastery Connect para evaluar a sus estudiantes y elaborar planes de estudios.</span></li>
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;'>Las Instituciones acad&eacute;micas y los educadores pueden utilizar recursos basados en est&aacute;ndares para comprobar los conocimientos y evaluar el grado de aprendizaje de los estudiantes.</span></li>
                                                </ul>
                                            </td>
                                            <td style="padding:.75pt .75pt .75pt .75pt;"><br></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td style="padding:.75pt .75pt .75pt .75pt;"><br></td>
                            <td style="padding:.75pt .75pt .75pt .75pt;"><br></td>
                        </tr>
                    </tbody>
                </table>
               <br>

                <table style="border: none;border-collapse:collapse; text-align: left;">
                    <tbody>
                        <tr>
                            <td style="padding:.75pt .75pt .75pt .75pt;">
                                <table style="border: none;border-collapse:collapse;">
                                    <tbody>
                                        <tr>
                                            <td style="padding:.75pt .75pt .75pt .75pt;">
                                                <table style="border: none;border-collapse:collapse;">
                                                    <tbody>
                                                        <tr>
                                                            <td style="background:#F2F2F2;padding:.75pt .75pt .75pt .75pt;">
                                                                <table style="border: none;border-collapse:collapse;">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style="background:#F2F2F2;padding:.75pt .75pt .75pt .75pt;">
                                                                                <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-size:16px;font-family:  "Times New Roman",serif;color:black;'>Elevate de Instructure:&nbsp;</span></strong></p>
                                                                                <ul style="margin-bottom:0in;margin-top:0in;" type="disc">
                                                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Elevate K-12 Analytics</span></li>
                                                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Elevate Data Quality</span></li>
                                                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Elevate Data Hub</span></li>
                                                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Elevate Data Sync</span></li>
                                                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Elevate Standards&nbsp;</span></li>
                                                                                </ul>
                                                                            </td>
                                                                            <td style="background:#F2F2F2;padding:.75pt .75pt .75pt .75pt;">
                                                                                <ul style="margin-bottom:0in;margin-top:0in;" type="disc">
                                                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Las Instituciones acad&eacute;micas pueden utilizar Elevate Data Quality para asegurarse de que los datos est&eacute;n actualizados y sean precisos.</span></li>
                                                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Las Instituciones acad&eacute;micas pueden utilizar Elevate K-12 Analytics para proporcionar datos integrados a fin de obtener informaci&oacute;n sobre lo que afecta al &eacute;xito de los estudiantes.</span></li>
                                                                                </ul>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                            <td style="background:#F2F2F2;padding:.75pt .75pt .75pt .75pt;"><br></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td style="padding:.75pt .75pt .75pt .75pt;"><br></td>
                                            <td style="padding:.75pt .75pt .75pt .75pt;"><br></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td style="padding:.75pt .75pt .75pt .75pt;"><br></td>
                            <td style="padding:.75pt .75pt .75pt .75pt;"><br></td>
                        </tr>
                    </tbody>
                </table>
               <br>

                <table style="border: none;border-collapse:collapse; text-align: left;">
                    <tbody>
                        <tr>
                            <td style="padding:.75pt .75pt .75pt .75pt;">
                                <table style="border: none;border-collapse:collapse;">
                                    <tbody>
                                        <tr>
                                            <td style="padding:.75pt .75pt .75pt .75pt;">
                                                <table style="border: none;border-collapse:collapse;">
                                                    <tbody>
                                                        <tr>
                                                            <td style="background:#F2F2F2;padding:.75pt .75pt .75pt .75pt;">
                                                                <table style="border: none;border-collapse:collapse;">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style="background:#F2F2F2;padding:.75pt .75pt .75pt .75pt;">
                                                                                <table style="border: none;border-collapse:collapse;">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td style="padding:.75pt .75pt .75pt .75pt;">
                                                                                                <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-size:16px;font-family:"Times New Roman",serif;'>Impact de Instructure</span></strong></p>
                                                                                            </td>
                                                                                            <td style="padding:.75pt .75pt .75pt .75pt;">
                                                                                                <ul style="margin-bottom:0in;margin-top:0in;" type="disc">
                                                                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;'>Las Instituciones acad&eacute;micas pueden utilizar Impact para impulsar la adopci&oacute;n de tecnolog&iacute;a en un distrito.</span></li>
                                                                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;'>Tambi&eacute;n pueden utilizar Impact para evaluar los efectos de la tecnolog&iacute;a en el compromiso y los resultados de los estudiantes.</span></li>
                                                                                                </ul>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                            <td style="background:#F2F2F2;padding:.75pt .75pt .75pt .75pt;"><br></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                            <td style="background:#F2F2F2;padding:.75pt .75pt .75pt .75pt;"><br></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td style="padding:.75pt .75pt .75pt .75pt;"><br></td>
                                            <td style="padding:.75pt .75pt .75pt .75pt;"><br></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td style="padding:.75pt .75pt .75pt .75pt;"><br></td>
                            <td style="padding:.75pt .75pt .75pt .75pt;"><br></td>
                        </tr>
                    </tbody>
                </table>
               <br>

                <table style="border: none;border-collapse:collapse;">
                    <tbody>
                        <tr>
                            <td style="padding:.75pt .75pt .75pt .75pt;">
                                <table style="border: none;border-collapse:collapse;">
                                    <tbody>
                                        <tr>
                                            <td style="padding:.75pt .75pt .75pt .75pt;">
                                                <table style="border: none;border-collapse:collapse;">
                                                    <tbody>
                                                        <tr>
                                                            <td style="background:#F2F2F2;padding:.75pt .75pt .75pt .75pt;">
                                                                <table style="border: none;border-collapse:collapse;">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style="background:#F2F2F2;padding:.75pt .75pt .75pt .75pt;">
                                                                                <table style="border: none;border-collapse:collapse;">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td style="padding:.75pt .75pt .75pt .75pt;">
                                                                                                <table style="border: none;border-collapse:collapse;">
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td style="background:#F2F2F2;padding:.75pt .75pt .75pt .75pt;">
                                                                                                                <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:  "Times New Roman",serif;color:black;'>Estudiantes</span></p>
                                                                                                                <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:  "Times New Roman",serif;color:black;'>Instituciones acad&eacute;micas&nbsp;</span></p>
                                                                                                                <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:  "Times New Roman",serif;color:black;'>Profesionales</span></p>
                                                                                                                <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Progenitores/tutores</span></p>
                                                                                                            </td>
                                                                                                            <td style="background:#F2F2F2;padding:.75pt .75pt .75pt .75pt;">
                                                                                                                <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-size:16px;font-family:  "Times New Roman",serif;color:black;'>Canvas Student ePortfolios</span></strong></p>
                                                                                                                <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Portfolium</span></strong></p>
                                                                                                            </td>
                                                                                                            <td style="background:#F2F2F2;padding:.75pt .75pt .75pt .75pt;">
                                                                                                                <ul style="margin-bottom:0in;margin-top:0in;" type="disc">
                                                                                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:.25in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Los estudiantes pueden mostrar sus logros a posibles empresas y compa&ntilde;eros de estudios y ponerse en contacto con otros usuarios, como empleadores, profesionales y otros estudiantes.</span></li>
                                                                                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Los empleadores pueden buscar y seleccionar candidatos a un puesto.</span></li>
                                                                                                                </ul>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </td>
                                                                                            <td style="padding:.75pt .75pt .75pt .75pt;"><br></td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                            <td style="background:#F2F2F2;padding:.75pt .75pt .75pt .75pt;"><br></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                            <td style="background:#F2F2F2;padding:.75pt .75pt .75pt .75pt;"><br></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td style="padding:.75pt .75pt .75pt .75pt;"><br></td>
                                            <td style="padding:.75pt .75pt .75pt .75pt;"><br></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td style="padding:.75pt .75pt .75pt .75pt;"><br></td>
                            <td style="padding:.75pt .75pt .75pt .75pt;"><br></td>
                        </tr>
                    </tbody>
                </table>
               <br>
               <br>

                <h4>2. Recopilación de la información.</h4>
               <p>
                  Ahora que conoce nuestros Productos, deseamos informarle sobre los datos que estos recopilan y procesan.
               </p>
               <h5>Información personal</h5>
               <p>
                    Información que puede identificarle individualmente, ya sea en solitario o en combinación con otros datos como, por ejemplo:
               </p>
                <ol>
                    <li><span>&#x2022; Nombre y apellidos</span></li>
                    <li><span>&#x2022; G&eacute;nero o pronombres preferidos</span></li>
                    <li><span>&#x2022; Direcci&oacute;n de correo electr&oacute;nico</span></li>
                    <li><span>&#x2022; Instituci&oacute;n acad&eacute;mica&nbsp;</span></li>
                    <li><span>&#x2022; Nombre de usuario y contrase&ntilde;a</span></li>
                    <li><span>&#x2022; Informaci&oacute;n geogr&aacute;fica como, por ejemplo, su ubicaci&oacute;n</span></li>
                    <li><span>&#x2022; Informaci&oacute;n del perfil (como una breve biograf&iacute;a o la fotograf&iacute;a del perfil)</span></li>
                    <li><span>&#x2022; N&uacute;mero de tel&eacute;fono fijo o m&oacute;vil&nbsp;</span></li>
                    <li><span>&#x2022; Datos de tarjetas de cr&eacute;dito (solo para Canvas Catalog)</span></li>
                    <li><span>&#x2022; Informaci&oacute;n de perfil importada de sitios web de terceros si decide registrarse o iniciar sesi&oacute;n con credenciales en ellos</span></li>
                    <li><span>&#x2022; Informaci&oacute;n de Instituciones acad&eacute;micas para inscribir su perfil (como, por ejemplo, el n&uacute;mero de ID de estudiante)</span></li>
                    <li><span>&#x2022; En mensajes de la aplicaci&oacute;n o comentarios de grupos de debate</span></li>
                    <li><span>&#x2022; Fecha de nacimiento (solo para los productos Portfolium, Mastery Connect y Elevate)</span></li>
                    <li><span>&#x2022; Contenido presentado (como trabajos de investigaci&oacute;n o tareas de clase)</span></li>
                    <li><span>&#x2022; Tipos de datos descritos en la&nbsp;</span><a href="https://www.ed-fi.org/" target="_blank"><span style='font-size:16px;font-family:"Times New Roman",serif;color:#0F80C1;'>norma de datos Ed-Fi</span></a><span>&nbsp;(solo para los productos Elevate)</span></li>
                    <li><span>&#x2022; Im&aacute;genes de v&iacute;deo y grabaciones de voz</span></li>
                </ol>
               <br>

               <p>
                    Recopilamos su información personal en los siguientes casos:
               </p>
                <ol style="margin-bottom:0in;margin-top:0in;" type="disc">
                    <li><span>&#x2022; Cuando crea una cuenta para utilizar nuestros Productos.</span></li>
                    <li><span>&#x2022; Cuando una Instituci&oacute;n acad&eacute;mica crea una cuenta para usted en nuestros Productos.</span></li>
                    <li><span>&#x2022; Cuando importa informaci&oacute;n a sitios web o fuentes de terceros como, por ejemplo, redes sociales.</span></li>
                    <li><span>&#x2022; Cuando otros le invitan a unirse a nuestros Productos.</span></li>
                    <li><span>&#x2022; Cuando una Instituci&oacute;n acad&eacute;mica transfiere datos de los sistemas de informaci&oacute;n de los estudiantes u otros sistemas institucionales a nuestros productos.</span></li>
                </ol>
               <br>

               <h5>Información del uso de productos</h5>
                <p>
                    Al utilizar nuestros Productos o subcontratados, se procesa la siguiente información:
                </p>
                <ol>
                  <li>&#x2022; Sus interacciones con otros usuarios, por ejemplo, mensajes, comentarios y archivos cargados.</li>
                  <li>&#x2022; El rendimiento de los Productos como, por ejemplo, cómo utiliza determinadas funciones.</li>
                  <li>&#x2022; Descripciones, imágenes, hashtags y otros datos asociados a la información cargada.</li>
                  <li>&#x2022; El modo en que utiliza nuestros Productos, como el tiempo de interacción con determinadas funciones y la fecha de las visitas.</li>
                </ol>
               <p>
                 Al utilizar nuestros Productos, recopilamos información acerca de cómo los usa.
               </p>

               <br>

               <h5>Comunicaciones y otras interacciones con nosotros</h5>
               <p>
                    Cualquier información, incluidos los datos personales, que envíe durante sus interacciones con nosotros como, por ejemplo:
               </p>
                <ol>
                  <li>&#x2022; Información de contacto</li>
                  <li>&#x2022; Contenido de correo electrónico</li>
                </ol>
               <br>

               <p>
                 Recopilamos información de sus comunicaciones e interacciones con nosotros y sobre ellas. Por ejemplo, recopilamos información cuando:
               </p>
                <ol>
                  <li>&#x2022; Solicita asistencia de nuestro servicio de atención al cliente.</li>
                  <li>&#x2022; Nos proporciona comentarios sobre nuestros Productos.</li>
                  <li>&#x2022; Se inscribe en una conferencia, seminario web o evento de Lógiga-Escuela y asiste a él.</li>
                  <li>&#x2022; Participa en encuestas y concursos.</li>
                  <li>&#x2022; Se comunica o interactúa con nosotros de algún otro modo.</li>
                </ol>
               <br>

               <h5>Información de dispositivos</h5>
                <p>
                    Información, incluidos datos personales, de y sobre su dispositivo, como, por ejemplo:
                </p>
                <ol>
                  <li>&#x2022; Tipo, configuración y preferencias del navegador</li>
                  <li>&#x2022; Sistema operativo</li>
                  <li>&#x2022; Información de ubicación (por ejemplo, país o estado)</li>
                  <li>&#x2022; Identificadores persistentes</li>
                  <li>&#x2022; Dirección IP (Protocolo de Internet) (un número que se asigna automáticamente a su ordenador cuando utiliza Internet y que puede variar de una sesión a otra)</li>
                  <li>&#x2022; Nombre de dominio</li>
                  <li>&#x2022; Identificadores exclusivos de dispositivos</li>
                </ol>
                <p>
                    Cuando utiliza nuestros Productos en un dispositivo, como la mayoría de los servicios de Internet, recopilamos y almacenamos automáticamente la información del dispositivo.
                </p>
               <br>

               <h5>Información profesional</h5>
                <p>
                    Información sobre logros labores y profesionales como, por ejemplo:
                </p>
               <ol>
                 <li>&#x2022; Información solicitada por el empleador, que puede incluir información personal, como su curriculum vitae.</li>
                 <li>&#x2022; Descripciones y requisitos de trabajo</li>
               </ol>
               <p>
                 Recopilamos esta información, por ejemplo, cuando solicita un puesto laboral publicado en Portfolium o en los ePortfolios de los estudiantes y activa una cuenta creada por su Institución académica.
               </p>
               <br>
               <br>

               <h4>3.	El uso que hacemos de su Información.</h4>
                <p>
                    Ahora que ya conoce cómo procesamos la información, examinemos lo que hacemos con ella.
                    Tenga en cuenta que si procesamos su información personal para un fin distinto al que se recopilaron, le avisaremos con antelación del nuevo tratamiento y obtendremos su consentimiento si es necesario.
                </p>
                <p>
                  Limitamos la recopilación y el uso de su información personal solo a los elementos necesarios para el funcionamiento de nuestros Productos, o bien como se indica en este Aviso de Privacidad. No tomamos parte en la toma automática de decisiones, la publicidad a estudiantes ni la elaboración de perfiles.
                </p>
               <br>

               <h5>Para proporcionar, analizar y mejorar nuestros Productos</h5>
               <p>
                 Utilizamos la información descrita anteriormente para proporcionar, analizar y mejorar nuestros Productos, incluido para:
               </p>
               <ol>
                 <li>&#x2022; Crear y mantener su cuenta.</li>
                 <li>&#x2022; Identificarle como usuario.</li>
                 <li>&#x2022;Anotar y asignar tiques de asistencia. </li>
                 <li>&#x2022; Proporcionar, usar, mantener y mejorar nuestros Productos.</li>
                 <li>&#x2022; Personalizar y mejorar su experiencia.</li>
                 <li>&#x2022; Ponerse en contacto y comunicarse con usted, incluidas las respuestas a comentarios o dudas.</li>
                 <li>&#x2022; Proporcionar asistencia al cliente.</li>
                 <li>&#x2022; Solicitar opiniones sobre nuestros Productos, incluso invitándole a participar en encuestas o cuestionarios (con su permiso).</li>
               </ol>
               <br>

                <h5>Uso sin referencias personales o agregado</h5>
               <p>
                    Podemos crear y utilizar información sin referencias personales o agregada, es decir, información de la que se han eliminado detalles específicos que puedan identificarle individualmente (es decir, información no personal) para cualquier fin.
               </p>
               <br>
               <br>


               <h4>4.	Divulgación de la información.</h4>
               <p>
                  Ahora abordaremos cómo compartimos o divulgamos la información, incluidos los datos personales. Tenga en cuenta que compartimos información y le permitimos compartir información, solo como se describe en este Aviso de privacidad. No vendemos ni alquilamos información personal a terceros.
               </p>
               <br>

               <h5>4.1 Divulgación de la información de Lógiga-Escuela.</h5>
               <p>
                  Lógiga-Escuela puede compartir información con los siguientes destinatarios o como se establece a continuación.
               </p>
                <ol>
                  <li><b>A)	Entidades de propiedad común.</b> Es posible que compartamos sus datos, incluida la información personal, con empresas de propiedad o control comunes de Lógiga-Escuela, incluidas filiales y subsidiarias, para ayudar a proporcionar nuestros Productos.</li>
                  <li><b>B)	Proveedores de servicios externos.</b> Es posible que compartamos su información personal con proveedores de servicios externos con la única finalidad de proporcionarle nuestros Productos. </li>
                </ol>

                <p>
                    No permitimos que nuestros proveedores de servicios externos utilicen la información personal que compartimos con ellos para sus propios fines publicitarios o de marketing, o para cualquier otro fin que no esté relacionado con los servicios que prestan a Lógiga-Escuela. Además, no vendemos ni alquilamos su información personal a terceros.
                </p>
                <ol>
                  <li>
                    <b>C)	Requisitos legales.</b> En determinadas circunstancias, podemos estar obligados a desvelar información, incluidos datos personales, para:
                    <ol>
                        <li>&#x2022; Cumplir con procesos legales o normativos (como un procedimiento legal, una orden judicial o una investigación gubernamental).</li>
                        <li>&#x2022; Aplicar la Política de uso aceptable de Lógiga-Escuela y otras políticas aplicables.</li>
                        <li>&#x2022; Responder a reclamaciones de contenido que infringe derechos de terceros.</li>
                        <li>&#x2022; Proteger los derechos, la propiedad o la seguridad personal de Lógiga-Escuela, sus empleados, sus usuarios, sus clientes y el público.</li>
                    </ol>
                  </li>
                  <li><b>D)	Cambio de control.</b> Podemos compartir información sobre usted en relación con o durante la negociación de cualquier fusión, financiación, adquisición, quiebra, disolución, transacción o procedimiento que implique la venta, la transferencia, la desinversión o la divulgación de todo o parte de nuestro negocio o activos a otra empresa. En caso de que debamos compartir información de esta forma, se publicará un aviso en nuestro sitio web: <a href="https://escuela.logigacorp.com/" target="_blank" style="color:#0F80C1">https://escuela.logigacorp.com/</a></li>
                  <li><b>E)	Con su consentimiento o bajo su dirección.</b> Es posible que compartamos información distinta a la descrita en este Aviso de privacidad con su consentimiento o bajo su dirección específica, por ejemplo, si decide utilizar los Productos con plataformas de redes sociales (como Facebook, YouTube, Twitter, etc.).</li>
                  <li><b>F)	Uso sin referencias personales o agregado.</b> Podemos compartir información sin referencias personales o agregada, es decir, información de la que se han eliminado detalles específicos que puedan identificarle individualmente (es decir, información no personal) para cualquier fin sin limitaciones a menos que lo prohíba la legislación vigente.</li>
                </ol>
               <br>

               <h5>4.2 Divulgación de información a través de los Productos de Lógiga-Escuela.</h5>
                <p>
                    Nuestros Productos presentan funciones que comparten información (incluida información personal) con terceros autorizados o que le permiten compartir información con terceros o con el público. Estos tipos de divulgación se describen a continuación.
                </p>
                <ol>
                    <li>
                      <b>A)	Instituciones académicas, proveedores de cursos o progenitores.</b> Es posible que compartamos su información personal con la Institución académica o la empresa que esté vinculada al uso que realice de nuestros Productos. Si se permite, también podremos compartir su información con partes pertinentes asociadas a la Institución académica, como educadores o compañeros de estudios, o progenitores de estudiantes que utilicen nuestros Productos u otros usuarios. Por ejemplo, esto puede aplicarse a:
                      <ol>
                          <li>&#x2022; Si su Institución académica utiliza nuestros Productos como plataforma de gestión del aprendizaje y le ha dado acceso a los Productos, o bien</li>
                          <li>&#x2022; Si está realizando un curso a través de los Productos, compartiremos la información personal que nos proporcionó en el momento del registro de la cuenta y la inscripción en el curso con el educador o la Institución académica</li>
                      </ol>
                    </li>
                  <li><b>B)	Otros usuarios externos.</b> Puede optar por compartir información a través de nuestros Productos, con otros usuarios o con el público cuando publique contenidos o proporcione información sobre usted. No nos responsabilizamos del uso que otros realicen de la información disponible, por lo que debe decidir cuidadosamente si publica información y el contenido que comparte, además de la forma de identificarse. </li>
                  <li><b>C)	Con su consentimiento o bajo su dirección.</b> Como se ha indicado, es posible que compartamos información distinta a la descrita en este Aviso de privacidad con su consentimiento o bajo su dirección específica, por ejemplo, si decide utilizar los Productos con plataformas de redes sociales (como Facebook, YouTube, Twitter, etc.).</li>
                </ol>
               <br>
               <br>

               <h4>5.	Su información bajo su control.</h4>
               <p>Le ofrecemos varias formas de controlar su información. </p>
               <p>
                  <b>Información de la cuenta.</b> Puede cambiar parte de su información personal mediante la modificación de su perfil del Producto. También puede solicitar que modifiquemos o eliminemos su información mediante el envío de un mensaje de correo electrónico a <a href="mailto:escuela@logigacorp.com" style="color:#0F80C1">escuela@logigacorp.com</a> o de un tique de atención al cliente a través de nuestros Productos. Responderemos a su solicitud, cuando lo permita la ley y salvo excepciones, en un plazo de 30 días. Es posible que no podamos eliminar la información incluida en nuestros archivos.
               </p>
                <p>
                    <b>Notificaciones «push».</b> Si desea dejar de recibir notificaciones «push», utilice la configuración de su dispositivo móvil para ello. Tenga en cuenta que, si decide dejar de recibir notificaciones «push», esto puede afectar a su uso de nuestros Productos (como la recepción de una notificación de un nuevo mensaje). Tenga en cuenta que el proceso de exclusión voluntaria varía en función del dispositivo móvil que utilice.
                </p>
                <p>
                    <b>Ubicación.</b> Es posible que algunos Productos recopilen información de ubicación sobre su dispositivo móvil, pero solo con su consentimiento. Si desea revocar el permiso para recopilar información de ubicación precisa, puede cambiar la configuración de localización del dispositivo móvil.
                </p>
                <p>
                    Si necesita acceder a información personal que no esté disponible a través de cualquiera de los mecanismos descritos anteriormente, póngase en contacto con nosotros en <a href="mailto:escuela@logigacorp.com" style="color:#0F80C1">escuela@logigacorp.com</a> o envíenos un ticket de atención al cliente a través de nuestros Productos.
                </p>
               <br>
               <br>


               <h4>6.	Otra información importante.</h4>
               <p>
                  <b>Acuerdos de privacidad de los datos de los estudiantes.</b> Cuando proporcionamos nuestros Productos a determinadas Instituciones académicas de EE. UU., podemos firmar un Acuerdo de privacidad de datos de estudiantes («SDPA», por sus siglas en inglés) de alcance estatal con un Organismo educativo local («LEA», por sus siglas en inglés). Los LEA hacen públicos estos SDPA. Puede acceder a los SDPA que hemos firmado con los LEA en el sitio web del Consorcio de privacidad de datos de estudiantes.
               </p>

                <p>
                    <b>Sitios web de terceros.</b> Nuestros Productos contienen enlaces a sitios web o servicios de terceros que le permiten salir de nuestros Productos e ir a otro sitio web al hacer clic en un enlace de este tipo. Estas entidades independientes pueden recopilar su información, incluidos datos personales. No tenemos ningún control, realizamos revisiones ni podemos responsabilizarnos de estos sitios web externos o de su contenido.
                </p>

               <p>
                  Tenga en cuenta que los términos de este Aviso de privacidad no se aplican a estos sitios web o contenidos externos ni a la recopilación de datos después de hacer clic en los enlaces a esos sitios web externos. Los enlaces a sitios web o ubicaciones de terceros se ofrecen para su comodidad y no representan nuestra aprobación de esos terceros o de sus productos, contenidos o sitios web.
               </p>
               <br>
               <br>


                <h4>7.	Seguridad de la Información personal.</h4>
                <p>Lógiga-Escuela toma medidas razonables para ayudar a proteger su información personal en un esfuerzo por evitar el acceso, la divulgación o el uso no autorizados.</p>
                <p>A pesar de estas medidas, debe saber que no podemos eliminar por completo los riesgos de seguridad asociados a la información personal. Ningún método de transmisión a través de Internet o de almacenamiento electrónico es 100 % seguro. Por lo tanto, no podemos garantizar la seguridad absoluta. Al usar nuestros Productos, la publicación o la introducción de contenido es por su cuenta y riesgo.</p>
                <p>Tenga en cuenta que la información personal que recopilamos puede transferirse y almacenarse en servidores o bases de datos ubicados fuera de su país y que puede estar accesible para las autoridades policiales y de seguridad nacional en esas jurisdicciones.</p>
               <br>
               <br>


                <h4>8.	Información de contacto.</h4>
                <p>Lógiga-Escuela agradece sus comentarios o preguntas sobre este Aviso de privacidad. Envíenos un mensaje de correo electrónico a <a href="mailto:escuela@logigacorp.com" style="color:#0F80C1">escuela@logigacorp.com</a> o póngase en contacto con nosotros en la siguiente dirección o número de teléfono:</p>
                <p>Lógiga-Escuela.</p>
                <p>Av. Francisco de Orellana, Edificio World Trade Center, Torre A, Piso 5, Oficina 510</p>
                <p>042-631457</p>
                <p><a href="mailto:escuela@logigacorp.com" style="color:#0F80C1">escuela@logigacorp.com</a></p>
               <br>
               <br>

               <h3>Cambios en este Aviso de privacidad</h3>
               <p>
                  Podemos modificar este Aviso de privacidad ocasionalmente. Si realizamos cambios en este Aviso, modificaremos la «Fecha de entrada en vigor» indicada arriba. Si esos cambios son importantes, se publicará una notificación sobre ellos junto con el Aviso de privacidad modificado. Le recomendamos que visite esta página de vez en cuando para conocer las últimas novedades sobre nuestras prácticas de privacidad.
               </p>
               <br>
               <br>

                <p>
                    Actualizado al 28 de febrero de 2023.
                </p>
             </div>
        </div>
      </div>
   </div>
  </section>

  <Footer footerPadding="true"/>
</template>

<script>
import Header from '../components/Home/Header.vue';
import Breadcrumb from '../components/common/Breadcrumb.vue';

import Footer from '../components/Home/Footer.vue';

export default {
   name:'PoliticasPrivacidad',
   components:{
		Header,
      Breadcrumb,

      Footer,
	}
}
</script>
