<template>
   <section class="about__area pt-90 pb-50">
      <div class="container">
         <div class="row">
            <div class="col-xxl-5 offset-xxl-1 col-xl-6 col-lg-6">
               <div class="about__thumb-wrapper">

<!--                  <div class="about__review">-->
<!--                     <h5> <span>8,200+</span> five ster reviews</h5>-->
<!--                  </div>-->
                  <div class="about__thumb">
                     <img src="../../assets/img/logiga/otros_cursos.jpeg" alt="">
                  </div>
<!--                  <div class="about__banner mt&#45;&#45;210">-->
<!--                     <img src="../../assets/img/about/about-banner.jpg" alt="">-->
<!--                  </div>-->
<!--                  <div class="about__student ml-270 mt&#45;&#45;80">-->
<!--                     <a href="#">-->
<!--                        <img src="../../assets/img/about/student-4.jpg" alt="">-->
<!--                        <img src="../../assets/img/about/student-3.jpg" alt="">-->
<!--                        <img src="../../assets/img/about/student-2.jpg" alt="">-->
<!--                        <img src="../../assets/img/about/student-1.jpg" alt="">-->
<!--                     </a>-->
<!--                     <p>Join over <span>4,000+</span> students</p>-->
<!--                  </div>-->
               </div>
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-6">
               <div class="about__content pr-90 pt-20">
                  <div class="section__title-wrapper mb-25">
<!--                     <h2 class="section__title">Achieve your <br><span class="yellow-bg-big">Goals <img src="../../assets/img/shape/yellow-bg-2.png" alt="">-->
<!--                     </span>  with Educal </h2>-->
                    <h2 class="section__title">Quiénes <span class="yellow-bg-big">Somos <img src="../../assets/img/shape/yellow-bg-2.png" alt=""></span>
                     </h2>
                     <p style="text-align: justify; font-size: 18px;">
                        Somos un grupo de practicantes y Expertos en Comercio Exterior comprometidos con darte soluciones prácticas y alcanzables, enfocados en el desarrollo integral del talento humano; creamos, desarrollamos e implementamos proyectos de capacitación a la medida de las necesidades de tu empresa y las exigencias de tu equipo de trabajo, garantizando el aprendizaje y el desarrollo de habilidades aplicables al entorno laboral y personal.
                     </p>
                  </div>
<!--                  <div class="about__list mb-35">-->
<!--                     <ul>-->
<!--                        <li class="d-flex align-items-center"> <i class="icon_check"></i> Upskill your organization.</li>-->
<!--                        <li class="d-flex align-items-center"> <i class="icon_check"></i> Access more then 100K online courses</li>-->
<!--                        <li class="d-flex align-items-center"> <i class="icon_check"></i> Learn the latest skills</li>-->
<!--                     </ul>-->
<!--                  </div>-->
<!--                  <router-link to="/contact" class="e-btn e-btn-border">apply now</router-link>-->
               </div>
            </div>
         </div>
      </div>
   </section>
</template>

<script>
export default {
   name:'AboutArea'
};
</script>
