<template>
  <section class="banner__area pb-110">
    <div class="container mt-80">
      <div class="row">
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
          <div
            class="banner__item p-relative mb-40" style="min-height: 230px;"
            :style="{background:'url('+ require('../../assets/img/logiga/foto_7_576x266.jpg')+')',backgroundRepeat:'no-repeat',backgroundSize:'cover'}"
          >
            <div class="banner__content">
<!--              <span>Free</span>-->
<!--              <h3 class="banner__title">-->
<!--                <router-link to="/course-details"-->
<!--                  >Más de  <br />-->
<!--                  Lógiga Escuela</router-link>-->
<!--              </h3>-->
<!--              <router-link to="/cursos" class="e-btn e-btn-2">Ver</router-link>-->
              <a href="https://escuela.logigacorp.com/" target="_blank" class="e-btn e-btn-2">Más de Lógiga Escuela</a>
            </div>
<!--            <div class="banner__thumb d-none d-sm-block d-md-none d-lg-block">-->
<!--              <img src="../../assets/img/banner/banner-img-1.png" alt="" />-->
<!--            </div>-->
          </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
          <div
            class="banner__item p-relative mb-40" style="min-height: 230px;"
            :style="{background:'url('+ require('../../assets/img/logiga/foto_8_576x266.jpg')+')',backgroundRepeat:'no-repeat',backgroundSize:'cover'}"
          >
            <div class="banner__content">
<!--              <span class="orange">new</span>-->
<!--              <h3 class="banner__title">-->
<!--                <router-link to="/course-details">Más de <br />Canvas </router-link>-->
<!--              </h3>-->
<!--              <router-link to="/cursos" class="e-btn e-btn-2">Ver</router-link>-->
              <a href="https://www.instructure.com/es/" target="_blank" class="e-btn e-btn-2">Más de Instructure</a>
            </div>
<!--            <div-->
<!--              class="-->
<!--                banner__thumb banner__thumb-2-->
<!--                d-none d-sm-block d-md-none d-lg-block-->
<!--              "-->
<!--            >-->
<!--              <img src="../../assets/img/banner/banner-img-2.png" alt="" />-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BannerArea",
};
</script>
