<template>
    <loading
        v-model:active="mostrarLoader"
        color="#0F80C1"
        loader="dots"
    />
    <CoursesItems :courses="courseItems" ref="coursesItemsContainer"/>
</template>

<script>
import CourseMixin from '../../mixins/courseItemsMixin';
import CoursesItems from "@/components/courseItems/CoursesItems";
import axios from '@/axios';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
   name:'GridTab',
   mixins:[CourseMixin],
   components:{
      CoursesItems,
      Loading
   },
    data () {
        return {
            courseItems: [],
            mostrarLoader: false,
        }
    },
    methods:{
        getCursos () {
            this.openLoadingInDiv()
            if(this.$route.name=='consulta-cursos'){
                axios.get('api/curso/').then(response => {
                    this.courseItems = response.data.results;
                    // this.total_paginas = (response.data.count)/this.page_size;
                    // this.pagina_actual = 1
                    this.closeLoadingInDiv()
                   }
                );
            }
            else if(this.$route.name=='consulta-programas'){
                axios.get('api/programa/').then(response => {
                    this.courseItems = response.data.results;
                    // this.total_paginas = (response.data.count)/this.page_size;
                    // this.pagina_actual = 1
                    this.closeLoadingInDiv()
                   }
                );
            }
        },
        openLoadingInDiv(){
            this.mostrarLoader = true
        },
        closeLoadingInDiv(){
            this.mostrarLoader = false
        }
    },
    mounted() {
      this.getCursos()
    },
    computed: {
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      }
    }
};


</script>
