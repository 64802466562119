import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap/scss/bootstrap.scss';
import router from './router';
import { store } from './store'
import 'swiper/css/bundle';
import './assets/css/fontAwesome5Pro.css';
import 'bootstrap';
import BootstrapVue3 from "bootstrap-vue-3";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import './assets/scss/main.scss';
import './assets/scss/custom.scss';
// import Loading from 'vue-loading-overlay';
// import 'vue-loading-overlay/dist/css/index.css';
// import { BootstrapVue, LayoutPlugin, ModalPlugin, CardPlugin, DropdownPlugin, TablePlugin } from 'bootstrap-vue'

// import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import VTooltip from 'v-tooltip'

let app = createApp(App);
app.use(router)
app.use(BootstrapVue3);
app.use(store)

app.use(VueSweetalert2);

app.use(VTooltip)

// app.use(LoadingPlugin, {
//     color: '#0F80C1',
//     loader: 'dots'
// });

// BSV Plugin Registration
// app.use(BootstrapVue)
// app.use(LayoutPlugin)
// app.use(ModalPlugin)
// app.use(CardPlugin)
// app.use(DropdownPlugin)
// app.use(TablePlugin)

//Font Awesome
// app.component('font-awesome-icon', FontAwesomeIcon)

// Vuetify
// import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
})

app.use(vuetify)


//Global validators
import { defineRule } from 'vee-validate';
import { required, email } from '@vee-validate/rules';
import { verificarCedula, verificarRuc } from "@/assets/utils/actions.js";

defineRule('required', required);

defineRule('email', email);

defineRule('terminosCondiciones', value => {
  if (!value) {
    return 'Debe aceptar los términos y condiciones';
  }
  return true;
});

defineRule('isCedula', value => {
  const validationCedula = verificarCedula(value)
  if(!validationCedula){
    return 'Ingrese una cédula válida'
  }
  return validationCedula
});

defineRule('isRuc', value => {
  const validationRuc = verificarRuc(value)
  if(!validationRuc){
    return 'Ingrese un RUC válido'
  }
  return validationRuc
});

import { configure } from 'vee-validate';
import { localize } from '@vee-validate/i18n';

configure({
  generateMessage: localize('es', {
    messages: {
      required: 'Este campo es obligatorio',
      email: 'Debe ser un email válido'
    },
  }),
});


app.mount('#app');
