<template>
  <header>
    <div id="header-sticky"
      :class="`${isSticky ? 'header__area header__transparent header__padding sticky'
      :'header__area header__transparent header__padding'} ${header__white && header__white}`"
    >
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-2 col-sm-4 col-6">
            <div class="header__left d-flex">
              <div class="logo">
                <router-link to="/">
                  <img v-if="header__white && !isSticky"
                  src="../../assets/img/logo/logiga-escuela-long-logo-white.png" alt="logo" style="width: 150px; height: 40px;"/>

                  <img v-else src="../../assets/img/logo/logiga-escuela-long-logo.png" alt="logo" style="width: 150px; height: 40px;"/>
                </router-link>
              </div>
<!--              <div class="header__category d-none d-lg-block">-->
<!--                <nav>-->
<!--                  <ul>-->
<!--                    <li>-->
<!--                      <router-link-->
<!--                        to="/cursos"-->
<!--                        class="cat-menu d-flex align-items-center"-->
<!--                      >-->
<!--                        <div class="cat-dot-icon d-inline-block">-->
<!--                          <svg viewBox="0 0 276.2 276.2">-->
<!--                            <g>-->
<!--                              <g>-->
<!--                                <path-->
<!--                                  class="cat-dot"-->
<!--                                  d="M33.1,2.5C15.3,2.5,0.9,17,0.9,34.8s14.5,32.3,32.3,32.3s32.3-14.5,32.3-32.3S51,2.5,33.1,2.5z"-->
<!--                                />-->
<!--                                <path-->
<!--                                  class="cat-dot"-->
<!--                                  d="M137.7,2.5c-17.8,0-32.3,14.5-32.3,32.3s14.5,32.3,32.3,32.3c17.8,0,32.3-14.5,32.3-32.3S155.5,2.5,137.7,2.5    z"-->
<!--                                />-->
<!--                                <path-->
<!--                                  class="cat-dot"-->
<!--                                  d="M243.9,67.1c17.8,0,32.3-14.5,32.3-32.3S261.7,2.5,243.9,2.5S211.6,17,211.6,34.8S226.1,67.1,243.9,67.1z"-->
<!--                                />-->
<!--                                <path-->
<!--                                  class="cat-dot"-->
<!--                                  d="M32.3,170.5c17.8,0,32.3-14.5,32.3-32.3c0-17.8-14.5-32.3-32.3-32.3S0,120.4,0,138.2S14.5,170.5,32.3,170.5z"-->
<!--                                />-->
<!--                                <path-->
<!--                                  class="cat-dot"-->
<!--                                  d="M136.8,170.5c17.8,0,32.3-14.5,32.3-32.3c0-17.8-14.5-32.3-32.3-32.3c-17.8,0-32.3,14.5-32.3,32.3    C104.5,156.1,119,170.5,136.8,170.5z"-->
<!--                                />-->
<!--                                <path-->
<!--                                  class="cat-dot"-->
<!--                                  d="M243,170.5c17.8,0,32.3-14.5,32.3-32.3c0-17.8-14.5-32.3-32.3-32.3s-32.3,14.5-32.3,32.3    C210.7,156.1,225.2,170.5,243,170.5z"-->
<!--                                />-->
<!--                                <path-->
<!--                                  class="cat-dot"-->
<!--                                  d="M33,209.1c-17.8,0-32.3,14.5-32.3,32.3c0,17.8,14.5,32.3,32.3,32.3s32.3-14.5,32.3-32.3S50.8,209.1,33,209.1z    "-->
<!--                                />-->
<!--                                <path-->
<!--                                  class="cat-dot"-->
<!--                                  d="M137.6,209.1c-17.8,0-32.3,14.5-32.3,32.3c0,17.8,14.5,32.3,32.3,32.3c17.8,0,32.3-14.5,32.3-32.3    S155.4,209.1,137.6,209.1z"-->
<!--                                />-->
<!--                                <path-->
<!--                                  class="cat-dot"-->
<!--                                  d="M243.8,209.1c-17.8,0-32.3,14.5-32.3,32.3c0,17.8,14.5,32.3,32.3,32.3c17.8,0,32.3-14.5,32.3-32.3    S261.6,209.1,243.8,209.1z"-->
<!--                                />-->
<!--                              </g>-->
<!--                            </g>-->
<!--                          </svg>-->
<!--                        </div>-->
<!--                        <span>Category</span>-->
<!--                      </router-link>-->
<!--                      <ul class="cat-submenu">-->
<!--                        <li>-->
<!--                          <router-link to="/course-details">English Learning</router-link>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                          <router-link to="/course-details">Web Development</router-link>-->
<!--                        </li>-->
<!--                        <li><router-link to="/course-details">Logo Design</router-link></li>-->
<!--                        <li>-->
<!--                          <router-link to="/course-details">Motion Graphics</router-link>-->
<!--                        </li>-->
<!--                        <li><router-link to="/course-details">Video Edition</router-link></li>-->
<!--                      </ul>-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </nav>-->
<!--              </div>-->
            </div>
          </div>
          <div class="col-xxl-9 col-xl-9 col-lg-8 col-md-10 col-sm-8 col-6">
            <div
              class="
                header__right
                d-flex
                justify-content-end
                align-items-center
              "
            >
              <div :class="`main-menu ${header__white && 'main-menu-3'}`">
                <nav id="mobile-menu" class="d-none d-xl-block">
                  <ul>
                    <li><router-link to="/">Inicio</router-link></li>
                    <li><router-link to="/cursos">Cursos</router-link></li>
                    <li><router-link to="/programas">Programas</router-link></li>
                    <li><router-link to="/blogs">Blog</router-link></li>
                    <li><router-link to="/nosotros">Nosotros</router-link></li>
                    <li><router-link to="/contacto">Contacto</router-link></li>
<!--                    <li class="has-dropdown">-->
<!--                      <router-link to="/">Home</router-link>-->
<!--                      <ul class="submenu">-->
<!--                        <li><router-link to="/home">Home Style 1</router-link></li>-->
<!--                        <li><router-link to="/home-two">Home Style 2</router-link></li>-->
<!--                        <li><router-link to="/home-three">Home Style 3</router-link></li>-->
<!--                      </ul>-->
<!--                    </li>-->
<!--                    <li class="has-dropdown">-->
<!--                      <router-link to="/courses">Courses</router-link>-->
<!--                      <ul class="submenu">-->
<!--                        <li><router-link to="/courses">Courses</router-link></li>-->
<!--                        <li><router-link to="/courses-list">Course List</router-link></li>-->
<!--                        <li>-->
<!--                          <router-link to="/courses-sidebar">Course sidebar</router-link>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                          <router-link to="/course-details">Course Details</router-link>-->
<!--                        </li>-->
<!--                      </ul>-->
<!--                    </li>-->
<!--                    <li class="has-dropdown">-->
<!--                      <router-link to="/blog">Blog</router-link>-->
<!--                      <ul class="submenu">-->
<!--                        <li><router-link to="/blog">Blog</router-link></li>-->
<!--                        <li><router-link to="/blog-details">Blog Details</router-link></li>-->
<!--                      </ul>-->
<!--                    </li>-->
<!--                    <li class="has-dropdown">-->
<!--                      <router-link to="/courses">Pages</router-link>-->
<!--                      <ul class="submenu">-->
<!--                        <li><router-link to="/about">About</router-link></li>-->
<!--                        <li><router-link to="/instructor">Instructor</router-link></li>-->
<!--                        <li>-->
<!--                          <router-link to="/instructor-details">-->
<!--                          Instructor Details</router-link>-->
<!--                        </li>-->
<!--                        <li><router-link to="/event-details">Event Details</router-link></li>-->
<!--                        <li><router-link to="/cart">My Cart</router-link></li>-->
<!--                        <li><router-link to="/checkout">Checkout</router-link></li>-->
<!--                        <li><router-link to="/login">Sign In</router-link></li>-->
<!--                        <li><router-link to="/register">Sign Up</router-link></li>-->
<!--                        <li><router-link to="/errorPage">Error</router-link></li>-->
<!--                      </ul>-->
<!--                    </li>-->
<!--                    <li><router-link to="/contact">Contact</router-link></li>-->
                  </ul>
                </nav>
              </div>
              <div class="header__search p-relative ml-100 d-flex align-items-center">
<!--                <form action="#" class="d-none d-sm-block d-md-block">-->
<!--                  <input type="text" placeholder="Buscar..." />-->
<!--                  <button type="submit"><i class="fad fa-search"></i></button>-->
<!--                </form>-->
                <div class="header__cart" style="top: -10px!important;">
                  <a @click="handleMiniCart" href="javascript:void(0);" class="cart-toggle-btn">
                    <div class="header__cart-icon">
                      <svg viewBox="0 0 24 24">
                        <circle class="st0" cx="9" cy="21" r="1" />
                        <circle class="st0" cx="20" cy="21" r="1" />
                        <path
                          class="st0"
                          d="M1,1h4l2.7,13.4c0.2,1,1,1.6,2,1.6h9.7c1,0,1.8-0.7,2-1.6L23,6H6"
                        />
                      </svg>
                    </div>
                    <span class="cart-item">{{ carrito.length }}</span>
                  </a>
                  <v-tooltip
                    activator="parent"
                    location="bottom"
                  >
                      Ver carrito
                  </v-tooltip>
                </div>
              </div>

              <div class="header__btn ml-20 d-none d-md-block">
<!--                <router-link to="/contact" class="e-btn">Try for free</router-link>-->
                <a href="https://escuela.logigacorp.com/" target="_blank" class="e-btn"><i class="far fa-user"></i></a>
<!--                <a href="https://escuela.logigacorp.com/" target="_blank" class="e-btn">-->
<!--                  <img src="../../assets/img/logo/canvas-logo.png" alt="canvaslogo" class="img-fluid" style="width: 10px;height: 10px"/>-->
<!--                </a>-->
                  <v-tooltip
                    activator="parent"
                    location="bottom"
                  >
                      Iniciar sesión
                  </v-tooltip>
              </div>
              <div class="sidebar__menu d-xl-none">
                <div @click="handleSidebar" class="sidebar-toggle-btn ml-30" id="sidebar-toggle">
                  <span class="line"></span>
                  <span class="line"></span>
                  <span class="line"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>



<!-- cart mini area start -->
<div class="cartmini__area">
    <div :class="`${showMiniCart ? 'cartmini__wrapper opened' : 'cartmini__wrapper'}`">
      <div class="cartmini__title">
          <h4>Carrito de compras</h4>
      </div>
      <div @click="handleCartClose" class="cartmini__close">
          <button type="button" class="cartmini__close-btn">
            <i class="fal fa-times"></i>
          </button>
      </div>
      <div class="cartmini__widget">
          <div class="cartmini__inner">
            <ul>
                <li v-for="(course, index) in carrito" :key="course.token">
                  <div class="cartmini__thumb">
                      <a href="#">
                        <img v-if="course.course_image" :src="course.course_image" alt="course-image" style="border-radius: 10%;">
                        <img v-else src="@/assets/img/pages/not-available.png" alt="course-image">
                      </a>
                  </div>
                  <div class="cartmini__content">
                      <h5><a href="#">{{ course.name }} {{ course.modalidad_seleccionada }}</a></h5>
<!--                      <div class="product-quantity" style="visibility:hidden;">-->
<!--                        <span @click="handleDecreaseValue('valueOne')" class="cart-minus">-</span>-->
<!--                        <input class="cart-input" type="text" v-model="value"/>-->
<!--                        <span @click="handleIncreaseValue('valueOne')"-->
<!--                        class="cart-plus">+</span>-->
<!--                      </div>-->
                      <div class="course__status product__sm-price-wrapper mt-20" v-if="course.info_descuento_promocion && course.info_descuento_promocion.aplica_descuento">
                          <span class="green">$ {{ course.info_descuento_promocion.precio_postdescuento }}</span>
                          <span class="product__sm-price old-price">$ {{ course.precio }}</span>
                      </div>
                      <div class="course__status product__sm-price-wrapper mt-20" v-if="course.info_descuento_promocion && !course.info_descuento_promocion.aplica_descuento">
                          <span class="product__sm-price green">$ {{ course.precio }}</span>
                      </div>
                  </div>
                  <a href="javascript:void(0);" class="cartmini__del" @click="eliminarCursoEnCarrito(index)">
                      <i class="fal fa-times"></i>
                  </a>
                </li>
            </ul>
          </div>
          <div class="cartmini__checkout">
            <div class="course__status cartmini__checkout-title mb-30">
                <h4>Subtotal:</h4>
                <span class="green">$ {{ subtotalCarrito }}</span>
            </div>
            <div class="cartmini__checkout-btn">
                <router-link to="/carrito" class="e-btn e-btn-border mb-10 w-100"> <span></span> Ver carrito</router-link>
                <router-link to="/checkout" v-if="carrito.length>0" class="e-btn w-100"> <span></span> Proceder al pago</router-link>
            </div>
          </div>
      </div>
    </div>
</div>

<div @click="handleCartClose" :class="[showMiniCart ? 'body-overlay opened' : 'body-overlay']"></div>
<!-- cart mini area end -->

<!-- sidebar area start -->
  <div :class="`${showSidebar ? 'sidebar__area sidebar-opened' : 'sidebar__area'}`">
      <div class="sidebar__wrapper">
        <div @click="handleSidebarClose" class="sidebar__close">
            <button class="sidebar__close-btn" id="sidebar__close-btn">
            <span><i class="fal fa-times"></i></span>
            <span>cerrar</span>
            </button>
        </div>
        <div class="sidebar__content">
            <div class="logo mb-40">
              <router-link to="/">
                <img src="../../assets/img/logo/logiga-escuela-long-logo.png" alt="logo" style="width: 130px; height: 40px;">
              </router-link>
            </div>

             <div class="side-info-content sidebar-menu mm-menu">

                <ul>
                    <li><router-link to="/">Inicio</router-link></li>
                    <li><router-link to="/cursos">Cursos</router-link></li>
                    <li><router-link to="/programas">Programas</router-link></li>
                    <li><router-link to="/blogs">Blog</router-link></li>
                    <li><router-link to="/nosotros">Nosotros</router-link></li>
                    <li><router-link to="/contacto">Contacto</router-link></li>
<!--                  <li class="menu-item-has-children has-droupdown"-->
<!--                  v-bind:class="[menuOption.homeDropdown === true ? 'active' : '']">-->
<!--                      <a @click="menuOption.homeDropdown = !menuOption.homeDropdown">Home</a >-->
<!--                      <ul class="sub-menu" :class="[menuOption.homeDropdown === true ? 'active' : '',]">-->
<!--                        <li>-->
<!--                            <router-link to="/home">Home Style 1</router-link>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <router-link to="/home-two">Home Style 2</router-link>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <router-link to="/home-three">Home Style 3</router-link>-->
<!--                        </li>-->
<!--                      </ul>-->
<!--                  </li>-->


<!--                    <li class="menu-item-has-children has-droupdown"-->
<!--                      :class="[menuOption.coursesDropdown === true ? 'active' : '',]">-->
<!--                        <a @click="menuOption.coursesDropdown = !menuOption.coursesDropdown">Courses</a>-->
<!--                        <ul class="sub-menu" :class="[menuOption.coursesDropdown === true ? 'active' : '',]">-->
<!--                              <li>-->
<!--                                <router-link to="/courses">Courses</router-link>-->
<!--                              </li>-->
<!--                              <li>-->
<!--                                <router-link to="/courses-list">Course List</router-link>-->
<!--                              </li>-->
<!--                              <li>-->
<!--                                <router-link to="/courses-sidebar">Course sidebar-->
<!--                                </router-link>-->
<!--                              </li>-->
<!--                              <li>-->
<!--                                <router-link to="/course-details">Course Details-->
<!--                                </router-link>-->
<!--                              </li>-->
<!--                        </ul>-->
<!--                      </li>-->

<!--                  <li class="menu-item-has-children has-droupdown"-->
<!--                    :class="[menuOption.blogDropdown === true ? 'active' : '']">-->

<!--                    <a v-on:click="menuOption.blogDropdown = !menuOption.blogDropdown">Blog</a>-->
<!--                    <ul class="sub-menu"-->
<!--                      :class="[menuOption.blogDropdown === true ? 'active' : '',]">-->
<!--                      <li>-->
<!--                          <router-link to="/blog">Blog</router-link>-->
<!--                      </li>-->
<!--                      <li>-->
<!--                          <router-link to="/blog-details">Blog Details</router-link>-->
<!--                      </li>-->
<!--                    </ul>-->
<!--                  </li>-->


<!--                  <li class="menu-item-has-children has-droupdown"-->
<!--                  :class="[menuOption.pagesDropDown === true ? 'active' : '',]">-->
<!--                      <a @click="menuOption.pagesDropDown = !menuOption.pagesDropDown">Pages</a>-->
<!--                      <ul class="sub-menu"-->
<!--                      :class="[menuOption.pagesDropDown === true ? 'active' : '',]">-->
<!--                            <li><router-link to="/about">About</router-link></li>-->
<!--                            <li><router-link to="/instructor">Instructor</router-link></li>-->
<!--                            <li>-->
<!--                            <router-link to="/instructor-details">-->
<!--                            Instructor Details</router-link>-->
<!--                            </li>-->
<!--                            <li><router-link to="/event-details">Event Details</router-link></li>-->
<!--                            <li><router-link to="/cart">My Cart</router-link></li>-->
<!--                            <li><router-link to="/checkout">Checkout</router-link></li>-->
<!--                            <li><router-link to="/login">Sign In</router-link></li>-->
<!--                            <li><router-link to="/register">Sign Up</router-link></li>-->
<!--                            <li><router-link to="/errorPage">Error</router-link></li>-->
<!--                      </ul>-->
<!--                  </li>-->

<!--                  <li>-->
<!--                      <router-link to="/contact" class="border-0">Contact</router-link>-->
<!--                  </li>-->
              </ul>
            </div>

<!--            <div class="sidebar__search p-relative mt-40 ">-->
<!--              <form action="#">-->
<!--                  <input type="text" placeholder="Search...">-->
<!--                  <button type="submit"><i class="fad fa-search"></i></button>-->
<!--              </form>-->
<!--            </div>-->
            <div class="header__btn">
                <a href="https://escuela.logigacorp.com/" target="_blank" class="e-btn"><i class="far fa-user"></i></a>
                  <v-tooltip
                    activator="parent"
                    location="bottom"
                  >
                      Iniciar sesión
                  </v-tooltip>
            </div>

<!--            <div class="sidebar__cart mt-30">-->
<!--              <a @click="handleMiniCart" href="javascript:void(0);" class="cart-toggle-btn">-->
<!--                  <div class="header__cart-icon">-->
<!--                    <svg viewBox="0 0 24 24">-->
<!--                        <circle class="st0" cx="9" cy="21" r="1"/>-->
<!--                        <circle class="st0" cx="20" cy="21" r="1"/>-->
<!--                        <path class="st0" d="M1,1h4l2.7,13.4c0.2,1,1,1.6,2,1.6h9.7c1,0,1.8-0.7,2-1.6L23,6H6"/>-->
<!--                    </svg>-->
<!--                  </div>-->
<!--                  <span class="cart-item">{{ carrito.length }}</span>-->
<!--              </a>-->
<!--            </div>-->
        </div>
      </div>
  </div>
  <div @click="handleSidebarClose" :class="[showSidebar ? 'body-overlay opened' : 'body-overlay']"></div>
  <!-- sidebar area end -->


</template>

<script>
import { useCookies } from "vue3-cookies";
import rutasjson from '@/assets/utils/rutas.json'

export default {
  name: "HomeHeader",
  props:{
    header__white:String,
  },
  data () {
    return {
      isSticky:false,
      showMiniCart:false,
      showSidebar:false,
      value:1,
      valueTwo:1,
      valueThree:1,
      menuOption: {
        menuShow: false,
        menuSearch: false,
        homeDropdown: false,
        coursesDropdown: false,
        pagesDropDown: false,
        blogDropdown: false,
        aboutDropdown: false,
      },
      subtotalCarrito: this.$store.state.subtotal,
    }
  },
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  methods:{
      handleSticky () {
          if(window.scrollY > 80){
             this.isSticky = true;
          }
          else{
             this.isSticky = false;
          }
       },
       handleMiniCart () {
         this.updateSubtotalCarrito()
         this.showMiniCart = true;
       },
       handleCartClose () {
         this.showMiniCart = false;
       },
       handleSidebar () {
         this.showSidebar = true;
       },
       handleSidebarClose () {
          this.showSidebar = false;
       },
       handleIncreaseValue (value) {
         if(value === 'valueOne'){
           this.value++
         }
         if(value === 'valueTwo'){
           this.valueTwo++
         }
         if(value === 'valueThree'){
           this.valueThree++
         }

       },
       handleDecreaseValue (value) {
         if(value === 'valueOne' && this.value > 0){
           this.value--
         }
         if(value === 'valueTwo' && this.valueTwo > 0){
           this.valueTwo--
         }
         if(value === 'valueThree' && this.valueThree > 0){
           this.valueThree--
         }
       },

      obtenerCarrito(){
          let carrito = []
          let subtotal = 0.00
          if(this.cookies.get("carritoLogiga")){
              carrito = this.cookies.get("carritoLogiga")
              // console.log("carrito de header.vue es-------->")
              // console.log(carrito)
              this.$store.commit('setCarrito', JSON.parse(carrito))
          }
          else{
              this.cookies.set("carritoLogiga", JSON.stringify(carrito), '30d', '', rutasjson.domain)
              this.$store.commit('setCarrito', carrito)
          }
          if(this.cookies.get("carritoSubtotal")){
              subtotal = this.cookies.get("carritoSubtotal")
              this.$store.commit('setSubtotal', subtotal)
          }
          else{
              this.cookies.set("carritoSubtotal", subtotal, '30d', '', rutasjson.domain)
              this.$store.commit('setSubtotal', subtotal)
          }
      },
      eliminarCursoEnCarrito(indexCourse){
          this.$store.commit('eliminarCursoEnCarrito', indexCourse)
          let carrito = this.$store.getters.getCarrito

          let subtotal = 0.00
          carrito.forEach((course) => {
             let precio = 0.00
             if(course.info_descuento_promocion && course.info_descuento_promocion['aplica_descuento']){
                precio += parseFloat(course.info_descuento_promocion['precio_postdescuento'])
             }
             else{
                precio += parseFloat(course.precio)
             }
              subtotal += precio
          })

          this.$store.commit('setSubtotal', subtotal.toFixed(2))
          this.cookies.set("carritoSubtotal", subtotal.toFixed(2), '30d', '', rutasjson.domain)

          this.cookies.set("carritoLogiga", JSON.stringify(carrito), '30d', '', rutasjson.domain)

          this.updateSubtotalCarrito()
      },
      updateSubtotalCarrito(){
        this.subtotalCarrito = this.$store.state.subtotal
      }
  },
  computed: {
    carrito() {
      return this.$store.getters.getCarrito
    },
    // subtotalCarrito() {
    //   let subtotal = 0.00
    //   let carrito = JSON.parse(this.cookies.get("carritoLogiga"))
    //   carrito.forEach((course) => {
    //       subtotal += parseFloat(course.precio)
    //   })
    //
    //   return subtotal.toFixed(2)
    // }
  },
  mounted() {
      window.addEventListener('scroll',this.handleSticky)
      this.obtenerCarrito()
   },
};
</script>
