<template>
  <section class="blog__area pt-120 pb-120">
      <div class="container">
         <div class="row">
            <div class="col-12">
               <div class="row" v-if="blogs.length > 0">

                  <div v-for="blog in blogs" :key="blog.id" 
                  class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                     <div class="blog__wrapper">
                        <div class="blog__item white-bg mb-30 transition-3 fix">
                           <!-- <div class="blog__thumb w-img fix">
                              <router-link :to="`/blog-details/${blog.id}`">
                                 <img :src="blog.blogImg" alt="">
                              </router-link>
                           </div> -->
                           <div class="blog__content">
                              <div class="blog__tag">
                                 <a href="#" class="green">Nuevo</a>
                              </div>
                              <h3 class="blog__title">
                                 <router-link :to="`/blog/${blog.id}`">
                                 {{blog.title}}</router-link>
                              </h3>
   
                              <div class="blog__meta d-flex align-items-center justify-content-between">
                                 <!-- <div class="blog__author d-flex align-items-center">
                                    <div class="blog__author-thumb mr-10">
                                       <img src="../../assets/img/blog/author/author-4.png" alt="">
                                    </div>
                                    <div class="blog__author-info">
                                       <h5>Lógiga Escuela de Negocios</h5>
                                    </div>
                                 </div> -->
                                 <div class="blog__date d-flex align-items-center">
                                    <i class="fal fa-clock"></i>
                                    <span>{{blog.date}}</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div v-else class="text-center">
                  No hay registros para mostrar
              </div>
               <!-- Pagination start -->
               <!-- <Pagination/> -->
               <!-- Pagination end -->
            </div>
            <!-- BlogRightSide start  -->
            <!-- <BlogRightSide/> -->
            <!-- BlogRightSide end  -->
         </div>
      </div>
   </section>
</template>

<script>
// import Pagination from '../common/Pagination.vue';
// import BlogRightSide from './BlogRightSide.vue';
import BlogMixin from '../../mixins/blogMixin';
import axios from '@/axios';

export default {
   name:'BlogArea',
   mixins:[BlogMixin],
   components:{
      // Pagination,
      // BlogRightSide,
   },
   data() {
      return {
         blogs: {},
      }
   },
   methods: {
      getBlogs(){
         // this.openLoadingInDiv()
         axios.get('wagtail-api/pages/?type=blog.BlogPage&fields=body,date').then(response => {
            this.blogs = response.data.items;
            // console.log(this.blogs)
            // this.closeLoadingInDiv()
         })
      }
   },
   created() {
      this.getBlogs();
   }
};
</script>
