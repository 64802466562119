<template>
    <section class="course__area pt-120 pb-120">
       <div class="container">
          <div class="row">
             <div class="col-xxl-12">
                <div class="course__tab-conent">
                   <div class="tab-content" id="courseTabContent">
                      <div class="tab-pane fade show active" id="grid" role="tabpanel" aria-labelledby="grid-tab">
                         <div class="row">
                            <!-- GridTabItems  -->
                            <GridTabItems/>
                            <!-- GridTabItems  -->
                         </div>
                      </div>
                      </div>
                </div>
             </div>
          </div>
       </div>
    </section>
</template>

<script>
import GridTabItems from './GridTabItems.vue';

export default {
   name:'ProgramasArea',
   components:{
      GridTabItems,
   }
};
</script>
