import { createStore } from 'vuex'

export const store = createStore({
  state: {
    carrito: [],
    subtotal: 0.00
  },
  getters: {
    getCarrito (state) {
      const str = JSON.stringify(state.carrito)
      return JSON.parse(str)
    },
    getSubtotal (state) {
      return state.subtotal
    }
  },
  mutations: {
    setCarrito(state, value){
      state.carrito = value
    },
    setSubtotal(state, value){
      state.subtotal = value
    },
    agregarCursoEnCarrito(state, payload){
      state.carrito.push(payload)
    },
    eliminarCursoEnCarrito(state, payload){
      state.carrito.splice(payload, 1)
    }
  },
  actions: {

  },
  strict: process.env.DEV,
})
